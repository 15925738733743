import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import LabelIcon from '@mui/icons-material/Label'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions.jsx'
import { pathToPerson, renderContent } from '../utils/helpers.js'
import IntegerDetails from './IntegerDetails.jsx'
import SearchSelect from './SearchSelect.jsx'
import TextFieldQuestion from './TextFieldQuestion.jsx'

const filter = createFilterOptions()

const MedicalConditionsList = ({ id }) => {
  const { removePersonConditions } = useActions()
  const conditions =
    useSelector((state) => {
      const list = get(state, [...pathToPerson(id), 'conditions']) || []

      return list.filter(({ type }) => type === 'phenotype')
    }) || []

  return conditions.map((condition) => {
    const { label, qualifiers = [], treatments = [] } = condition
    const diagnosedAge = qualifiers.find(({ label }) => label === 'Age at diagnosis')?.type
    const hospital = treatments.find(({ type }) => type === 'other')?.treatmentLocation

    return (
      <Container key={condition.id}>
        <Card elevation={2} sx={{ borderRadius: 2 }}>
          <CardHeader
            sx={(theme) => ({ backgroundColor: theme.palette.white.main })}
            avatar={<LabelIcon color="primary" />}
            title={label}
            subheader={
              <Box display="flex" flexDirection="column" gap={0.5} pt={0.5}>
                {diagnosedAge ? (
                  <Typography variant="subtitle2">
                    Age of first diagnosis: <b>{diagnosedAge}</b>
                  </Typography>
                ) : null}
                {hospital ? (
                  <Typography variant="subtitle2">
                    Treating hospital: <b>{hospital}</b>
                  </Typography>
                ) : null}
              </Box>
            }
            action={
              <IconButton
                color="error"
                onClick={() => removePersonConditions({ id, conditionId: condition.id })}
              >
                <DeleteForeverIcon />
              </IconButton>
            }
          />
        </Card>
      </Container>
    )
  })
}
const DiagnosedAge = ({ diagnosedAge, setDiagnosedAge }) => (
  <FormControl>
    <FormLabel>Age of first diagnosis</FormLabel>
    <IntegerDetails value={diagnosedAge} onChange={setDiagnosedAge} />
  </FormControl>
)
const TreatingHospital = ({ hospital, setHospital }) => (
  <TextFieldQuestion
    label="Treating hospital"
    value={hospital}
    handleChange={setHospital}
    fullWidth
    multiline={false}
  />
)

export default function MedicalConditions({ id, label, config }) {
  const [modalOpened, setModalOpened] = useState(false)
  const [condition, setCondition] = useState({})
  const [diagnosedAge, setDiagnosedAge] = useState('')
  const [hospital, setHospital] = useState('')
  // convert vocabulary to { value, label } format for Autocomplete
  const hpoVocabulary = useSelector((state) =>
    get(state, ['hpoVocabulary', 'vocabularyTerms']),
  ).map((item) => ({ value: item.id, label: item.displayName }))
  const { setPersonConditions } = useActions()

  return (
    <>
      <FormControl key="addMedicalCondition">
        <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>
        <MedicalConditionsList id={id} />
        <Button
          sx={{ alignSelf: 'flex-start', mt: 2 }}
          startIcon={<AddIcon />}
          onClick={() => setModalOpened(true)}
        >
          Add Medical Condition
        </Button>
        <Dialog open={modalOpened} onClose={() => handleClose()}>
          <DialogTitle>Add Medical Condition</DialogTitle>
          <DialogContent>
            <Container>
              <SearchSelect
                key="searchMedicalConditions"
                label="Search Conditions"
                value={condition}
                options={hpoVocabulary}
                onChange={(_, newValue) => setCondition(newValue)}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const otherOption = {
                    value: 'Other',
                    label: 'Other',
                  }

                  // always keep other option in view
                  if (!filtered.find((item) => item.value === otherOption.value)) {
                    filtered.push(otherOption)
                  }

                  return filtered
                }}
              />
              {condition?.value === 'Other' && (
                <TextFieldQuestion
                  key="otherCondition"
                  label="Please describe the condition"
                  value={condition.description || ''}
                  handleChange={(e) => setCondition((prev) => ({ ...prev, description: e }))}
                  fullWidth
                  multiline={false}
                />
              )}
              {renderContent({
                renderMap: {
                  relativesConditionsAge: (
                    <DiagnosedAge
                      key="relativesConditionsAge"
                      {...{ diagnosedAge, setDiagnosedAge }}
                    />
                  ),
                  patientMedicalConditionsAge: (
                    <DiagnosedAge
                      key="patientMedicalConditionsAge"
                      {...{ diagnosedAge, setDiagnosedAge }}
                    />
                  ),
                  patientMedicalConditionsHospital: (
                    <TreatingHospital
                      key="patientMedicalConditionsHospital"
                      {...{ hospital, setHospital }}
                    />
                  ),
                  relativesConditionsHospital: (
                    <TreatingHospital
                      key="relativesConditionsHospital"
                      {...{ hospital, setHospital }}
                    />
                  ),
                },
                config,
              })}
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" endIcon={<AddIcon />} onClick={addDiagnosis}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    </>
  )

  function addDiagnosis() {
    if (condition.value && condition.label) {
      const isOther = condition.value === 'Other'

      if (isOther && !condition.description) {
        handleClose()

        return
      }

      const conditionId = isOther ? condition.description : condition.value
      const conditionLabel = isOther ? condition.description : condition.label

      setPersonConditions({
        id,
        conditionId: condition.value,
        condition: {
          id: conditionId,
          label: conditionLabel,
          type: 'phenotype',
          qualifiers: diagnosedAge
            ? [
                {
                  label: 'Age at diagnosis',
                  type: diagnosedAge,
                },
              ]
            : [],
          treatments: hospital
            ? [
                {
                  type: 'other',
                  treatmentLocation: hospital,
                  isPerformed: 'Y',
                },
              ]
            : [],
        },
      })
    }
    handleClose()
  }

  function handleClose() {
    setCondition({})
    setDiagnosedAge('')
    setHospital('')
    setModalOpened(false)
  }
}
