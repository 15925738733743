import { createSlice } from '@reduxjs/toolkit'

export const hpoVocabularySlice = createSlice({
  name: 'hpoVocabulary',
  initialState: {},
  reducers: {
    setHpoVocabularyValues(_, action) {
      return action.payload
    },
  },
})

const { reducer, actions } = hpoVocabularySlice
export const { setHpoVocabularyValues } = actions

export default reducer
