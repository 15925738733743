import { Main } from './Main'
import { checkResponseForError, navigateToErrorScreen } from './utils/errors'

export class Setup extends Main {
  static baseUrl = this.getRoot()

  static async fetchData(url, fetchType) {
    try {
      const response = await fetch(url, {
        method: 'GET',
      })

      return await checkResponseForError(response)
    } catch (err) {
      navigateToErrorScreen({
        fetchType,
        status: err.message,
      })
    }
  }

  static async getLocalization() {
    const url = `${this.baseUrl}/config/localization`

    return this.fetchData(url, 'getLocalization')
  }

  static async getConfiguration(questionnaireType) {
    const url = `${this.baseUrl}/config/questionnaire/${questionnaireType}`

    return this.fetchData(url, 'getConfiguration')
  }

  static async getThankYou(questionnaireType) {
    const url = `${this.baseUrl}/config/page/thank-you/${questionnaireType}`

    return this.fetchData(url, 'getThankYou')
  }

  static async getHpoVocabulary() {
    // eslint-disable-next-line spellcheck/spell-checker
    const url = `${this.baseUrl}/config/vocabulary`

    return this.fetchData(url, 'getHpoVocabulary')
  }
}
