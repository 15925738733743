import { Box, Container, useMediaQuery } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { getLabel, renderContent, charRestriction, pathToPerson } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import TextFieldQuestion from './TextFieldQuestion.jsx'

const { FIRST_NAME, LAST_NAME } = PersonFieldPaths

export const Name = ({ type, id, isProband, localization, subQuestion }) => {
  const {
    setPersonFirstName,
    setPersonLastName,
    setPersonPreviousFirstName,
    setPersonPreviousLastName,
  } = useActions()
  const action = {
    previousNameFirst: setPersonPreviousFirstName,
    previousNameLast: setPersonPreviousLastName,
    nameFirst: setPersonFirstName,
    nameLast: setPersonLastName,
  }
  const labelField = {
    previousNameFirst: FIRST_NAME,
    previousNameLast: LAST_NAME,
    nameFirst: FIRST_NAME,
    nameLast: LAST_NAME,
  }
  const isPrevious = type === 'previousNameFirst' || type === 'previousNameLast'
  const value =
    useSelector((state) => {
      const nameType = {
        previousNameFirst: 'firstName',
        previousNameLast: 'lastName',
        nameFirst: 'firstName',
        nameLast: 'lastName',
      }[type]

      return isPrevious
        ? get(state, [...pathToPerson(id), 'previousName', nameType])
        : get(state, [...pathToPerson(id), 'name', nameType])
    }) || ''

  if (!action || !labelField) return

  return (
    <TextFieldQuestion
      label={
        (isPrevious ? 'Previous ' : '') +
        getLabel(localization, labelField[type]) +
        (isProband ? ' *' : '')
      }
      onChangeCapture={(e) => {
        e.target.value = charRestriction(e.target.value)
      }}
      handleChange={(_value) => action[type]({ id, value: _value })}
      value={value}
      multiline={false}
      subQuestion={subQuestion}
    />
  )
}

export const PreviousName = ({ id, localization }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  if (!id) return

  return (
    <Box display="flex" gap={3} flexWrap={isMobile && 'wrap'}>
      <Name type={'previousNameFirst'} {...{ id, localization, subQuestion: true }} />
      <Name type={'previousNameLast'} {...{ id, localization, subQuestion: true }} />
    </Box>
  )
}

export const PreferredName = ({ id }) => {
  const { setPersonPreferredName } = useActions()
  const preferredName = useSelector((state) =>
    get(state, [...pathToPerson(id), 'name', 'preferredName']),
  )

  return (
    <TextFieldQuestion
      label="Preferred Name"
      onChangeCapture={(e) => {
        e.target.value = charRestriction(e.target.value)
      }}
      handleChange={(value) => {
        setPersonPreferredName({ id, value })
      }}
      value={preferredName || ''}
      multiline={false}
    />
  )
}

function FullName({ id, isProband, localization, config }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const nameProps = { id, isProband, localization }

  if (!id) return

  return (
    <Container>
      <Box display="flex" gap={3} flexWrap={isMobile && 'wrap'}>
        {renderContent({
          renderMap: {
            nameFirst: <Name key="fn" type={'nameFirst'} {...nameProps} />,
            nameLast: <Name key="ln" type={'nameLast'} {...nameProps} />,
          },
          config,
        })}
      </Box>
      {renderContent({
        renderMap: { namePreferred: <PreferredName key="pn" {...nameProps} /> },
        config,
      })}
    </Container>
  )
}

export default FullName
