import _ from 'lodash'
import FamilyHelper, { RelationshipPaths, RelationshipTypes } from '../../../../utils/FamilyHelper'
import { getPerson } from '../../../slices/questionnaireSlice/utils/helpers'

export function getFamilyHelper(state) {
  return new FamilyHelper(state)
}

export function getPartnership(personId, state) {
  const { relationships } = state
  const fatherId = getFamilyHelper(state).resolveParent(personId, 'M', true, 'probandsFather').id
  const motherId = getFamilyHelper(state).resolveParent(personId, 'F', true, 'probandsMother').id

  const partnershipRelatives = relationships.find((rel) => {
    return (
      ((_.get(rel, RelationshipPaths.SOURCE) === fatherId &&
        _.get(rel, RelationshipPaths.TARGET) === motherId) ||
        (_.get(rel, RelationshipPaths.SOURCE) === motherId &&
          _.get(rel, RelationshipPaths.TARGET) === fatherId)) &&
      _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.PARTNERSHIP
    )
  })

  return (
    partnershipRelatives ||
    getFamilyHelper(state).addRelationship(fatherId, motherId, RelationshipTypes.PARTNERSHIP, [])
  )
}

export default {
  /**
   * Set person's ancestry: {}
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, parentSide: string, value: string[] }}
   * )}
   */
  setParentsAncestries(state, action) {
    const person = getPerson(state, action)
    const { parentSide, value } = action.payload

    if (!value.length) {
      delete person.ancestry[parentSide]

      if (!Object.keys(person.ancestry).length) {
        delete person.ancestry
      }

      return
    }

    if (!person.ancestry) {
      person.ancestry = {}
    }

    person.ancestry[parentSide] = value
  },
  /**
   * Set the value in `relationship` for the blood relation.
   * @param {object} state The global state for the questionnaire
   * @param {object} action The `action` with type and payload
   */
  setPartnershipProps(state, action) {
    // Just for checkup
    getPerson(state, action)

    const { id, relationProperties } = action.payload
    const familyHelper = new FamilyHelper(state)
    const partnership = getPartnership(id, state)

    familyHelper.removeRelationship(
      partnership.source,
      partnership.target,
      RelationshipTypes.PARTNERSHIP,
    )

    familyHelper.addRelationship(
      partnership.source,
      partnership.target,
      RelationshipTypes.PARTNERSHIP,
      relationProperties,
    )
  },
  /**
   * Set the value in `relationship` for the blood relation.
   * @param {object} state The global state for the questionnaire
   * @param {object} action The `action` with type and payload
   */
  setParentsRelationships(state, action) {
    const { parentsPerson = undefined, parentsRelationship } = action.payload

    if (parentsPerson) {
      state.persons = parentsPerson
    }

    state.relationships = parentsRelationship
  },
  /**
   * Add consanguinity to proband & partner relationship
   * @param {object} state The global state for the questionnaire
   */
  setPartnerConsanguinity(state) {
    const familyHelper = getFamilyHelper(state)
    const partner = familyHelper.resolveProbandPartner(state.probandId)
    const relationship = _.find(
      familyHelper.getRelationships(state.probandId),
      (rel) => rel.target === partner.id && rel.type === 'partnership',
    )

    relationship.properties = [
      ...relationship.properties,
      { type: 'consanguineous', isPresent: 'Y' },
    ]
  },
  /**
   * Remove consanguinity from proband & partner relationship
   * @param {object} state The global state for the questionnaire
   */
  removePartnerConsanguinity(state) {
    const familyHelper = getFamilyHelper(state)
    const partner = familyHelper.resolveProbandPartner(state.probandId)
    const relationship = _.find(
      familyHelper.getRelationships(state.probandId),
      (rel) => rel.target === partner.id && rel.type === 'partnership',
    )

    relationship.properties = _.filter(
      relationship.properties,
      (prop) => prop.type !== 'consanguineous',
    )
  },
  /**
   * Remove proband's partner and relationship
   * @param {object} state The global state for the questionnaire
   */
  removePartner(state) {
    const familyHelper = getFamilyHelper(state)
    const partner = familyHelper.resolveProbandPartner(state.probandId)
    if (partner) {
      familyHelper.removePerson(partner.id)
    }
  },
}
