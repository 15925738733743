import { FormGroup } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { useLocalization } from '../redux/reduxHooks.js'
import { getLabel, pathToPerson } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import ButtonGroup from './ButtonGroup'

/**
 * Selector for indicating twin of proband
 * @param {String} referencePersonId - newly created Sibling id
 * @param {String} resolvePersonId - The reference person, ie proband, mother, father...
 * @returns {React.ReactElement} Twin property selector
 **/
function HalfSiblingParent({ referencePersonId, resolvePersonId }) {
  const { setPersonPropertiesValue, setHalfSiblingSharedParentType } = useActions()
  const localization = useLocalization()
  const sharedParent =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(referencePersonId), 'properties']) || []

      return properties.find(({ type }) => type === 'sharedParent')
    }) || {}
  const onChange = (value) => {
    setPersonPropertiesValue({
      id: referencePersonId,
      type: 'sharedParent',
      value,
    })

    setHalfSiblingSharedParentType({
      personId: referencePersonId,
      designatedPersonId: resolvePersonId,
      sharedParentSex: value === 'Mother' ? 'F' : 'M',
    })
  }

  return (
    <FormGroup>
      <ButtonGroup
        onChange={onChange}
        selectedValue={sharedParent.value}
        options={[
          ['Mother', getLabel(localization, PersonFieldPaths.SHARED_PARENT_MOTHER, 'person')],
          ['Father', getLabel(localization, PersonFieldPaths.SHARED_PARENT_FATHER, 'person')],
        ]}
      />
    </FormGroup>
  )
}

export default HalfSiblingParent
