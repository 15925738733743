import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import Chooser from '../../widgets/Chooser'

const Consents = ({ display, label, selectedValue, onChange }) => {
  if (!display) return null

  return (
    <Chooser
      orderSchema={['Y', 'N']}
      path={'yesNoUnknown'}
      label={label}
      selectedValue={selectedValue}
      onChange={onChange}
    />
  )
}

export const PhoneConsent = ({ display }) => {
  const { setConsentsFollowUp } = useActions()
  const followUpConsent =
    useSelector((state) =>
      state.questionnaire.consents?.find((consent) => consent.type === 'followUpConsent'),
    ) || {}

  return (
    <Consents
      label="May we contact you by telephone?"
      selectedValue={followUpConsent.granted}
      onChange={(value) => setConsentsFollowUp({ value })}
      display={display}
    />
  )
}

export const VoicemailConsent = ({ display }) => {
  const { setConsentsVoicemail } = useActions()
  const voicemailConsent =
    useSelector((state) =>
      state.questionnaire.consents?.find((consent) => consent.type === 'voicemailConsent'),
    ) || {}

  return (
    <Consents
      label="May we leave a message if you do not pick up?"
      selectedValue={voicemailConsent.granted}
      onChange={(value) => setConsentsVoicemail({ value })}
      display={display}
    />
  )
}
