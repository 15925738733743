import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { renderContent, subQuestions } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'
import HeartSpecialist from './HeartSpecialist'

function HeartProblemDetails({ id, heartProblems = {}, config }) {
  const { setPersonConditionNotes } = useActions()

  return renderContent({
    renderMap: {
      heartProblemsDetails: (
        <TextFieldQuestion
          key="heartProblemsDetails"
          label="Please provide details"
          value={heartProblems.notes || ''}
          handleChange={(value) =>
            setPersonConditionNotes({ id, conditionId: 'heartProblems', value })
          }
          fullWidth
        />
      ),
      heartSpecialist: <HeartSpecialist key="heartSpecialist" {...{ id, heartProblems, config }} />,
    },
    config,
  })
}

function HeartProblems({ config, id }) {
  const { heartProblems = {} } = useSelector(({ questionnaire }) => ({
    heartProblems: questionnaire.persons[id].conditions?.find(({ id }) => id === 'heartProblems'),
  }))
  const {
    setPersonConditions,
    setPersonConditionIsPresent,
    setPersonConditionNotes,
    removePersonConditionTreatments,
  } = useActions()
  const { isPresent = '', notes = '' } = heartProblems
  const heartProblemsConfig = subQuestions({ config, key: 'heartProblems' })

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      label="Do you have a history of any heart problems?"
      conditionState={isPresent}
      onConditionStateChange={(value) => {
        if (!isPresent) {
          setPersonConditions({ id, conditionId: 'heartProblems' })
        }
        if (value !== isPresent) {
          setPersonConditionIsPresent({ id, conditionId: 'heartProblems', value })
        }
      }}
      cleanUpChildQuestions={() => {
        if (notes) setPersonConditionNotes({ id, conditionId: 'heartProblems', value: '' })
        if (heartProblems.treatments?.length > 0)
          removePersonConditionTreatments({
            id,
            conditionId: 'heartProblems',
            treatmentType: 'other',
          })
      }}
    >
      {renderContent({
        renderMap: {
          heartProblemsDetails: (
            <HeartProblemDetails
              key="heartProblemsDetails"
              id={id}
              heartProblems={heartProblems}
              config={heartProblemsConfig}
            />
          ),
        },
        config: heartProblemsConfig,
      })}
    </ConditionalSection>
  )
}

export default HeartProblems
