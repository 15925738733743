import { Box, Checkbox, FormControlLabel } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization, useProband } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths as paths, PersonProperties } from '../../utils/QuestionnaireStateManager'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const { HAS_BEEN_SEEN_BY_TEAM, HAS_RELATIVE_SEEN_BY_TEAM } = PersonProperties

export default function PreviouslySeen({ personId, personLabel, lastItemUnchecked }) {
  const { setPersonPropertiesIsPresent, setPersonPropertiesValue, removePersonPropertiesList } =
    useActions()
  const localization = useLocalization()
  const proband = useProband()
  const hasBeenSeenByTeam = useSelector((state) => {
    const properties = get(state, [...pathToPerson(personId), 'properties']) || []

    return properties.find(({ type }) => type === HAS_BEEN_SEEN_BY_TEAM)
  })
  const isChecked = !!hasBeenSeenByTeam

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <TextFieldQuestion
            label={getLabel(localization, paths.GENETIC_TESTING_DESCRIPTION, 'patient')}
            handleChange={(value) =>
              setPersonPropertiesValue({
                id: personId,
                type: HAS_BEEN_SEEN_BY_TEAM,
                value,
              })
            }
            value={hasBeenSeenByTeam?.value ?? ''}
            fullWidth
          />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    const { properties: probandProperties = [] } = proband
    const hasProbandProperty =
      probandProperties.find(({ type }) => type === HAS_RELATIVE_SEEN_BY_TEAM)?.isPresent !== 'Y'

    // on checkbox selected
    if (e.target.checked) {
      if (hasProbandProperty) {
        setPersonPropertiesIsPresent({
          id: proband.id,
          type: HAS_RELATIVE_SEEN_BY_TEAM,
          value: 'Y',
        })
      }
      setPersonPropertiesIsPresent({
        id: personId,
        type: HAS_BEEN_SEEN_BY_TEAM,
        value: 'Y',
      })

      return
    }

    // on checkbox deselected
    if (lastItemUnchecked()) {
      setPersonPropertiesIsPresent({
        id: proband.id,
        type: HAS_RELATIVE_SEEN_BY_TEAM,
        value: 'N',
      })
    }
    removePersonPropertiesList({
      id: personId,
      type: HAS_BEEN_SEEN_BY_TEAM,
    })
  }
}
