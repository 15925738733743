import FamilyHelper from '../../../../utils/FamilyHelper'

export default {
  /**
   * Add a generic person to persons list
   * @type {(state: WritableDraft<{}>, action: { payload: {
   *  relationshipToProband: string, sex: string?, id: string?
   * }})}
   */
  addPerson(state, action) {
    const { relationshipToProband, sex = 'U', id = null } = action.payload

    new FamilyHelper(state).addPerson(relationshipToProband, sex, id)
  },
  removePerson(state, action) {
    const { personId } = action.payload
    const familyHelper = new FamilyHelper(state)

    if (familyHelper.doesPersonExist(personId)) {
      familyHelper.removePerson(personId)
    }
  },
  addChild(state, action) {
    const { probandId, sex, targetPersonArray } = action.payload
    new FamilyHelper(state).addChild(probandId, sex, targetPersonArray)
  },
  addMiscarriage(state, action) {
    const { id, targetPersonArray, gestationAge } = action.payload
    new FamilyHelper(state).addMiscarriage(id, targetPersonArray, gestationAge)
  },
  addUnbornChild(state, action) {
    const { id, targetPersonArray, gestationAge } = action.payload
    new FamilyHelper(state).addUnbornChild(id, targetPersonArray, gestationAge)
  },
  setSiblings(state, action) {
    const { designatedPersonId, siblingSex, numberSharedParents, targetPersonArray } =
      action.payload

    const resolvedPersonId =
      typeof designatedPersonId === 'function' ? designatedPersonId(state) : designatedPersonId

    new FamilyHelper(state).setSiblingCount(
      resolvedPersonId,
      siblingSex,
      numberSharedParents,
      targetPersonArray,
    )
  },
  setHalfSiblingSharedParentType(state, action) {
    const { personId, designatedPersonId, sharedParentSex } = action.payload

    new FamilyHelper(state).setHalfSiblingSharedParentType(
      personId,
      designatedPersonId,
      sharedParentSex,
    )
  },
  setTwinRelationship(state, action) {
    const { siblingId, probandId, relPath } = action.payload

    const TwinProperties = {
      monozygoticTwin: 'monozygoticTwin',
      dizygoticTwin: 'dizygoticTwin',
    }

    const relationshipPath = TwinProperties[relPath]

    new FamilyHelper(state).setTwinRelationship(siblingId, probandId, relationshipPath)
  },
  resolveParent(state, action) {
    const { id, sex, shouldCreate, title } = action.payload

    new FamilyHelper(state).resolveParent(id, sex, shouldCreate, title)
  },
}
