import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { useLocalization } from '../redux/reduxHooks.js'
import { getLabel, pathToPerson } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import ConditionalSection from './ConditionalSection'
import DeathDetails from './DeathDetails'

export default function LifeStatus({ personId, isProband, config }) {
  const localization = useLocalization()
  const lifeStatusOption =
    useSelector((state) =>
      get(state, [...pathToPerson(personId), 'lifeStatus', 'lifeStatusOption']),
    ) || ''
  const { removePersonLifeStatus, setPersonLifeStatus } = useActions()
  const { ALIVE_STATUS } = PersonFieldPaths

  let alive
  switch (lifeStatusOption) {
    case 'alive':
      alive = 'Y'
      break
    case 'deceased':
      alive = 'N'
      break
    default:
      alive = null
  }

  return (
    <ConditionalSection
      label={getLabel(localization, ALIVE_STATUS)}
      showWhenCondition={isProband ? '' : 'N'}
      conditionState={alive}
      onConditionStateChange={(value) => {
        if (value !== alive) {
          setPersonLifeStatus({
            id: personId,
            type: 'lifeStatusOption',
            value: value === 'Y' ? 'alive' : 'deceased',
          })
        }
      }}
      orderSchema={['Y', 'N']}
      cleanUpChildQuestions={() => {
        removePersonLifeStatus({ id: personId, type: 'causeOfDeath' })
        removePersonLifeStatus({ id: personId, type: 'ageOfDeath' })
        removePersonLifeStatus({ id: personId, type: 'postMortemPerformed' })
      }}
    >
      <DeathDetails {...{ personId, localization, config }} />
    </ConditionalSection>
  )
}
