import { Container } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { renderContent, subQuestions } from '../../utils/helpers'
import Miscarriage from './Miscarriage'
import Pregnancy from './Pregnancy'
import RelatedToPartner from './RelatedToPartner'

const PregnancyReproduction = ({ config }) => {
  const probandId = useSelector((state) => get(state, ['questionnaire', 'probandId']))

  RemoveLoadingHook()

  const renderMap = {
    patientConsanguinity: <RelatedToPartner key="patientConsanguinity" id={probandId} />,
    patientMiscarriage: (
      <Miscarriage
        key="patientMiscarriage"
        id={probandId}
        targetPersonArray={['proband']}
        config={subQuestions({ config, key: 'patient-miscarriage' })}
      />
    ),
    geneticPregnancy: <Pregnancy key="pregnancy" id={probandId} />,
  }

  return <Container>{renderContent({ renderMap, config })}</Container>
}

export default PregnancyReproduction
