import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  FormLabel,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid/v4'
import { useActions } from '../../hooks/useActions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'
import AdditionalRelativesList from './AdditionalRelativesList'

const AdditionalRelatives = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const otherRelatives = useSelector((state) => state.questionnaire.additionalRelativeInfos) || []
  const { setAdditionalRelatives } = useActions()

  const [modalOpened, setModalOpened] = useState(false)
  const [additionalRelative, setAdditionalRelative] = useState(newAdditionalRelative())
  const [person, setPerson] = useState(newPerson())

  return (
    <>
      <FormLabel>
        Do you have any other relatives not previously mentioned that have been diagnosed with
        cancer or another condition?
      </FormLabel>
      <Button startIcon={<AddIcon />} onClick={() => setModalOpened(true)}>
        Add Other Relative
      </Button>
      {/* Modal Dialog */}
      <Dialog open={modalOpened} onClose={() => handleClose()}>
        <DialogTitle>Add Other Relative Info</DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '80vw' : '35vw' }}>
          <Container>
            <Box display="flex" gap={3}>
              <TextFieldQuestion
                label="First Name"
                value={person.name.firstName}
                handleChange={(value) =>
                  setPerson((prevPerson) => {
                    const newPerson = { ...prevPerson }
                    newPerson.name.firstName = value

                    return newPerson
                  })
                }
                multiline={false}
              />
              <TextFieldQuestion
                label="Last Name"
                value={person.name.lastName}
                handleChange={(value) =>
                  setPerson((prevPerson) => {
                    const newPerson = { ...prevPerson }
                    newPerson.name.lastName = value

                    return newPerson
                  })
                }
                multiline={false}
              />
            </Box>
            <TextFieldQuestion
              label="Relationship to Patient and Parental Side (e.g. Mother’s Cousin)"
              value={additionalRelative.relationshipToProband}
              handleChange={(value) =>
                setAdditionalRelative({ ...additionalRelative, relationshipToProband: value })
              }
              fullWidth
            />
            <TextFieldQuestion
              label="Medical Conditions (e.g. Breast cancer, colon cancer, etc.)"
              value={additionalRelative.notes}
              handleChange={(value) =>
                setAdditionalRelative({ ...additionalRelative, notes: value })
              }
              fullWidth
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            endIcon={<AddIcon />}
            onClick={addPerson}
            disabled={!person.name.firstName && !person.name.lastName}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* Person List */}
      <AdditionalRelativesList otherRelatives={otherRelatives} />
    </>
  )

  function handleClose() {
    setPerson(newPerson())
    setAdditionalRelative(newAdditionalRelative())
    setModalOpened(false)
  }
  function addPerson() {
    setAdditionalRelatives({ value: { ...additionalRelative, person } })
    handleClose()
  }
  function newPerson() {
    return { id: uuidv4(), name: { firstName: '', lastName: '' } }
  }
  function newAdditionalRelative() {
    return { id: uuidv4(), person: {}, relationshipToProband: '', notes: '' }
  }
}

export default AdditionalRelatives
