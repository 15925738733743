import { Container } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { renderContent, subQuestions } from '../../utils/helpers'
import CancerHistory from '../../widgets/CancerHistory'
import MedicalConditions from '../../widgets/MedicalConditions'
import BreastBiopsy from './BreastBiopsy'
import BreastMammogram from './BreastMammogram'
import Cancer from './Cancer'
import Colonoscopy from './Colonoscopy'
import Conditions from './Conditions'
import ElevatedPSA from './ElevatedPSA'
import Endometriosis from './Endometriosis'
import FIT from './FIT'
import MantleRadiation from './MantleRadiation'
import ProstateBiopsy from './ProstateBiopsy'
import Surgeries from './Surgeries'

function MedicalHistoryQuestions({ config }) {
  RemoveLoadingHook()
  const probandId = useSelector((state) => get(state, ['questionnaire', 'probandId'])) || ''
  const renderMap = {
    patientMedicalConditions: (
      <MedicalConditions
        key="patientMedicalConditions"
        label="Please list any medical conditions for which you have been diagnosed"
        id={probandId}
        config={subQuestions({ config, key: 'patient-medical-conditions' })}
      />
    ),
    cancerHx: (
      <Cancer key="can" id={probandId} patient={'patient'}>
        <CancerHistory personId={probandId} config={subQuestions({ config, key: 'cancer-hx' })} />
      </Cancer>
    ),
    mantleRadiation: <MantleRadiation key="mra" id={probandId} />,
    endometriosisStatus: <Endometriosis key="end" id={probandId} patient="patient" />,
    fitHx: (
      <FIT
        key="fit"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'fit-hx' })}
      />
    ),
    colonoscopyHx: (
      <Colonoscopy
        key="col"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'colonoscopy-hx' })}
      />
    ),
    breastMammogram: (
      <BreastMammogram
        key="bm"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'breast-mammogram' })}
      />
    ),
    breastBiopsy: (
      <BreastBiopsy
        key="bb"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'breast-biopsy' })}
      />
    ),
    prostateBiopsy: (
      <ProstateBiopsy
        key="pb"
        id={probandId}
        config={subQuestions({ config, key: 'prostate-biopsy' })}
      />
    ),
    psaResult: <ElevatedPSA key="ElevatedPSA" id={probandId} />,
    surgeries: <Surgeries key="Surgeries" id={probandId} />,
    conditions: (
      <Conditions
        key="Conditions"
        id={probandId}
        config={subQuestions({ config, key: 'conditions' })}
      />
    ),
  }
  const content = []
  renderContent({ renderMap, config, content })

  return <Container sx={{ gap: 4, pb: 8 }}>{content}</Container>
}

export default MedicalHistoryQuestions
