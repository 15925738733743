import { createAsyncThunk } from '@reduxjs/toolkit'
import uuidv4 from 'uuid/v4'
import { Auth } from '../../api/Auth'
import { Setup } from '../../api/Setup'
import { Update } from '../../api/Update'
import history from '../../utils/history'
import { EventType } from '../../utils/QuestionnaireStateManager'
import { setConfigurationValues } from '../slices/configurationSlice'
import { setHpoVocabularyValues } from '../slices/hpoVocabularySlice'
import { setLocalizationValues } from '../slices/localizationSlice'
import { setMetrics } from '../slices/metricSlice'
import { getNewMetrics } from '../slices/metricSlice/utils/helpers'
import { setState } from '../slices/questionnaireSlice'

const dispatchData = (dispatch, updated) => {
  dispatch(
    setState({
      ...updated.pedigree,
      isMetricMeasurementSystem: !!updated.isMetricMeasurementSystem,
      questionnaireType: updated.type,
      respondent: updated.respondent,
      notes: updated.notes,
      additionalHealthProblems: updated.additionalHealthProblems,
      relevantInfo: updated.relevantInfo,
      countryCode: updated.countryCode,
      additionalRelativeInfos: updated.additionalRelativeInfos,
      consents: updated.consents,
      answerInformations: updated.answerInformations,
    }),
  )
}

export const setInitialState = createAsyncThunk(
  'questionnaire/setInitialState',
  async ({ setAuthState, setLocalization, setConfiguration, setHpoVocabulary }, { dispatch }) => {
    const {
      pedigree,
      isMetricMeasurementSystem = undefined,
      countryCode,
      type,
      notes,
      additionalHealthProblems,
      relevantInfo,
      additionalRelativeInfos,
      respondent,
      consents,
      answerInformations,
    } = (await Auth.getSession()) || {}

    await setAuthState(() => ({
      isAuthenticated: true,
      loggingIn: false,
    }))

    const { probandId, persons } = pedigree

    if (!persons[probandId].relationshipToProband) {
      persons[probandId].relationshipToProband = 'proband'
    }

    const initValues = {
      isMetricMeasurementSystem: !!isMetricMeasurementSystem,
      countryCode,
      questionnaireType: type,
      notes,
      additionalHealthProblems,
      additionalRelativeInfos,
      relevantInfo,
      respondent,
      consents,
      answerInformations,
      ...pedigree,
    }

    dispatch(setState(initValues))
    await setConfiguration(type)
    await setLocalization()
    await setHpoVocabulary()
  },
)

export const sendStateData = createAsyncThunk(
  'questionnaire/sendStateData',
  async ({ shouldNotRunDispatch = false } = {}, { getState, dispatch }) => {
    const { questionnaire } = getState()
    const updated = await Update.updateQuestionnaire({ questionnaire })

    if (updated.errors || updated.error) {
      return updated
    }

    if (updated && !shouldNotRunDispatch) {
      dispatchData(dispatch, updated)
    }
  },
)

// Not required to implement redux thunk but for the future
// it is better placed here.
export const submitStateData = createAsyncThunk(
  'questionnaire/submitStateData',
  async ({ sendMetricsData }, { getState }) => {
    await sendMetricsData()
    const { questionnaire } = getState()

    const response = await Setup.getThankYou(questionnaire.questionnaireType)

    if (response) {
      const submit = await Update.submitQuestionnaire()

      if (submit) {
        history.push('/thankyou', { externalUrl: response['thank-you'] })
      }
    }
  },
)

export const resetState = createAsyncThunk(
  'questionnaire/resetState',
  async (_, { getState, dispatch }) => {
    const { questionnaire } = getState()
    const newProbandId = uuidv4()

    const initialState = {
      ...questionnaire,
      probandId: newProbandId,
      persons: {
        [newProbandId]: {
          id: newProbandId,
          relationshipToProband: 'proband',
        },
      },
      relationships: [],
      respondent: {},
      notes: '',
      additionalHealthProblems: '',
      additionalRelativeInfos: [],
      relevantInfo: '',
      consents: [],
      answerInformations: [],
    }

    const updated = await Update.updateQuestionnaire({ questionnaire: initialState })

    if (updated.errors || updated.error) {
      return updated
    }

    dispatchData(dispatch, updated)
  },
)

export const setLocalization = createAsyncThunk(
  'localization/setLocalization',
  async (_, { dispatch }) => {
    dispatch(setLocalizationValues(await Setup.getLocalization()))
  },
)

export const setConfiguration = createAsyncThunk(
  'configuration/setConfiguration',
  async (questionnaireType, { dispatch }) => {
    dispatch(setConfigurationValues(await Setup.getConfiguration(questionnaireType)))
  },
)

export const setHpoVocabulary = createAsyncThunk(
  'hpoVocabulary/setHpoVocabulary',
  async (_, { dispatch }) => {
    dispatch(setHpoVocabularyValues(await Setup.getHpoVocabulary()))
  },
)

export const sendMetricsData = createAsyncThunk(
  'metrics/setMetrics',
  async (
    {
      newDestinationValue = undefined,
      eventType = undefined,
      shouldNotRunDispatch = false,
      redirect = undefined,
    } = {},
    { getState, dispatch },
  ) => {
    /* No required to handle the http error. */

    // only for `back` button
    if (eventType === EventType.PREVIOUS_STEP || eventType === EventType.FEEDBACK) {
      await Auth.getSession()
    }

    const { questionnaire, metrics } = getState()
    const updatedMetrics = getNewMetrics({ questionnaire, metrics, newDestinationValue, eventType })
    const { sourcePage, destinationPage } = updatedMetrics

    let stepHistory = {
      destinationPage,
    }

    if (sourcePage) {
      stepHistory = {
        ...stepHistory,
        sourcePage,
      }
    }

    // For feedback link in ThankYou page.
    if (redirect) {
      redirect()
    }

    // Only for `submit`
    if (!shouldNotRunDispatch) {
      dispatch(setMetrics(stepHistory))
    }

    await Update.updateMetrics({ metricState: updatedMetrics })
  },
)
