import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const HAS_RELATIVE_HAD_MISCARRIAGE = 'hasRelativeHadMiscarriage'
const HAS_HAD_MISCARRIAGE = 'hasHadMiscarriage'

const RelativeMiscarriageList = ({ personId: id, personLabel, lastItemUnchecked }) => {
  const { setPersonPropertiesIsPresent, setPersonPropertiesValue, removePersonPropertiesList } =
    useActions()
  const probandId = useSelector((state) => get(state, ['questionnaire', 'probandId']))
  const hasRelativeHadMiscarriage =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(probandId), 'properties']) || []

      return properties.find(({ type }) => type === HAS_RELATIVE_HAD_MISCARRIAGE)
    }) || {}
  const hasHadMiscarriage =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find(({ type }) => type === HAS_HAD_MISCARRIAGE)
    }) || {}
  const isChecked = hasHadMiscarriage?.isPresent === 'Y'

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <TextFieldQuestion
            label="Additional notes"
            handleChange={(value) =>
              setPersonPropertiesValue({
                id,
                type: HAS_HAD_MISCARRIAGE,
                value,
              })
            }
            value={hasHadMiscarriage?.value ?? ''}
            fullWidth
          />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    const hasProbandProperty = hasRelativeHadMiscarriage?.isPresent === 'Y'

    // on checkbox selected
    if (e.target.checked) {
      if (!hasProbandProperty) {
        setPersonPropertiesIsPresent({
          id: probandId,
          type: HAS_RELATIVE_HAD_MISCARRIAGE,
          value: 'Y',
        })
      }
      setPersonPropertiesIsPresent({
        id,
        type: HAS_HAD_MISCARRIAGE,
        value: 'Y',
      })

      return
    }

    // on checkbox deselected
    if (lastItemUnchecked()) {
      setPersonPropertiesIsPresent({
        id: probandId,
        type: HAS_RELATIVE_HAD_MISCARRIAGE,
        value: 'N',
      })
    }
    removePersonPropertiesList({
      id,
      type: HAS_HAD_MISCARRIAGE,
    })
  }
}

export default RelativeMiscarriageList
