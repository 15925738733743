import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson, renderContent } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const Medications = ({ id, config }) => {
  const { setPersonPropertiesValue, setPersonPropertiesIsPresent } = useActions()
  const medication =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find((property) => property.type === 'isOnMedications')
    }) || {}

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      label="Do you take any medications?"
      conditionState={medication.isPresent ?? ''}
      onConditionStateChange={(value) => {
        setPersonPropertiesIsPresent({
          id,
          type: 'isOnMedications',
          value,
        })
      }}
      cleanUpChildQuestions={() => {
        if (medication.value) setPersonPropertiesValue({ id, type: 'isOnMedications', value: null })
      }}
    >
      {renderContent({
        renderMap: {
          medicationsDetails: (
            <TextFieldQuestion
              key="medications-details"
              label="Please provide details, including name and dosage:"
              handleChange={(value) => {
                setPersonPropertiesValue({ id, type: 'isOnMedications', value })
              }}
              value={medication.value ?? ''}
              subQuestion
              fullWidth
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default Medications
