import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Card,
  CardHeader,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import { useActions } from '../../hooks/useActions'

function AdditionalRelativesList({ otherRelatives }) {
  const { removeAdditionalRelatives } = useActions()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return otherRelatives.map(
    ({
      id,
      person: { name: { firstName = '', lastName = '' } = {} },
      relationshipToProband = '',
      notes = '',
    }) => (
      <Container key={firstName + lastName + relationshipToProband + notes}>
        <Card elevation={2} sx={{ borderRadius: 2 }}>
          <CardHeader
            sx={{
              backgroundColor: (theme) => theme.palette.white.main,
              py: 2,
              px: isMobile ? 2 : 3,
            }}
            avatar={<PersonIcon color="primary" />}
            title={firstName + ' ' + lastName}
            subheader={
              <Box display="flex" flexDirection="column" pt={1}>
                {relationshipToProband ? (
                  <Typography variant="subtitle2">
                    {'Relationship to proband: '} <b>{relationshipToProband}</b>
                  </Typography>
                ) : null}
                {notes ? (
                  <Typography variant="subtitle2">
                    {'Medical conditions: '} <b>{notes}</b>
                  </Typography>
                ) : null}
              </Box>
            }
            action={
              <IconButton
                color="error"
                onClick={() => {
                  removeAdditionalRelatives({ id })
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            }
          />
        </Card>
      </Container>
    ),
  )
}

export default AdditionalRelativesList
