import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson, subQuestions } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import MedicalConditions from '../../widgets/MedicalConditions'

function RelativeMedicalConditions({ personId, personLabel }) {
  const { removePersonConditions } = useActions()
  const [checked, setChecked] = useState(false)
  const conditions = useSelector((state) => {
    const list = get(state, [...pathToPerson(personId), 'conditions']) || []

    return list.filter(({ type }) => type === 'phenotype')
  })
  const baseConfig =
    useSelector((state) =>
      get(state, [
        'configuration',
        'sectionConfig',
        'medical-conditions',
        'subSectionConfig',
        'general-conditions',
        'questions',
      ]),
    ) || []
  const config = subQuestions({ config: baseConfig, key: 'relatives-conditions' })

  useEffect(() => {
    if (conditions.length > 0) {
      setChecked(true)
    }
  }, [])

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={checked} onChange={handleCheckbox} />}
      />
      {checked ? (
        <FollowUpQuestions>
          <MedicalConditions id={personId} label="Please list their conditions" config={config} />
        </FollowUpQuestions>
      ) : null}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox({ target: { checked } }) {
    // on checkbox selected
    if (checked) {
      setChecked(true)

      return
    }
    // on checkbox deselected
    setChecked(false)
    if (conditions.length > 0) {
      conditions.forEach((condition) => {
        removePersonConditions({ id: personId, conditionId: condition.id })
      })
    }
  }
}

export default RelativeMedicalConditions
