import { Container } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { renderContent, subQuestions } from '../../utils/helpers'
import Alcohol from './Alcohol'
import ContraceptivePills from './ContraceptivePills'
import HRT from './HRT'
import Medications from './Medications'
import Menopause from './Menopause'
import MenstrualPeriod from './MenstrualPeriod'
import RecreationalDrugs from './RecreationalDrugs'
import Tobacco from './Tobacco'

function GeneralHealthQuestions({ config }) {
  const probandId = useSelector((state) => get(state, ['questionnaire', 'probandId'])) || ''
  const renderMap = {
    menstrualAge: <MenstrualPeriod key="MenstrualPeriod" id={probandId} />,
    oralContraceptive: (
      <ContraceptivePills
        key="ContraceptivePills"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'oral-contraceptive' })}
      />
    ),
    menopause: (
      <Menopause
        key="Menopause"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'menopause' })}
      />
    ),
    hrtUse: (
      <HRT
        key="hrt"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'hrt-use' })}
      />
    ),
    tobacco: (
      <Tobacco
        key="tob"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'tobacco' })}
      />
    ),
    alcohol: (
      <Alcohol
        key="alc"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'alcohol' })}
      />
    ),
    medications: <Medications key="med" id={probandId} config={config} />,
    recreationalDrugs: <RecreationalDrugs key="rec-drugs" id={probandId} />,
  }
  const content = []
  renderContent({ renderMap, config, content })
  RemoveLoadingHook()

  return <Container sx={{ gap: 4, pb: 8 }}>{content}</Container>
}

export default GeneralHealthQuestions
