import { get, truncate, kebabCase } from 'lodash'

/** Utils */
const isValueEmpty = (value) => {
  return value === null || value === undefined || value === ''
}

export const isOnlyDigits = (value) => /^\d+$/.test(value)

export const getSiblingStr = (sex) => {
  return { M: 'brother', F: 'sister' }[sex] || 'sibling'
}

export const getOffspringStr = (sex) => {
  return { M: 'son', F: 'daughter' }[sex] || 'child'
}

export const getParentStr = (side) => {
  return {
    paternal: 'father',
    maternal: 'mother',
  }[side]
}

export const getParentStrFromSex = (sex) => {
  return {
    F: 'mother',
    M: 'father',
  }[sex]
}

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return ''
  }

  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const convertToCamelCase = (inputString, symbol) => {
  const stringArray = inputString.split(symbol)
  let camelCaseString = stringArray[0]

  for (const letter of stringArray.slice(1)) {
    camelCaseString += letter[0].toUpperCase() + letter.slice(1)
  }

  return camelCaseString
}

export const getParentSex = (side) => {
  return { paternal: 'M', maternal: 'F' }[side]
}

export const getGrandparentStr = (sex) => {
  return {
    M: 'Grandfather',
    F: 'Grandmother',
  }[sex]
}

export const getErrorMessage = (err) => {
  const message = get(err, 'response.body.message') || err.message || 'Unknown error'

  return truncate(message, {
    length: 400,
    omission: ' [...]',
  })
}

export const getLabel = (fieldDefinition, fieldPath, person, type = 'label') => {
  const labelPath = person ? `${person}.${fieldPath}.${type}` : `${fieldPath}.${type}`

  if (fieldDefinition && labelPath) {
    return fieldDefinition[`${labelPath}`]
  }

  return ''
}

export const getOptions = (fieldDefinition, fieldPath, orderSchema, person, type = 'label') => {
  return orderSchema.map((value) => {
    const key = person
      ? `${person}.${fieldPath}.options.${value}.${type}`
      : `${fieldPath}.options.${value}.${type}`

    return [value, fieldDefinition[key]]
  })
}

/**
 * Returns an display ready string based on presence of first and last name information.
 * It accounts for grammar in a situation where one or both of the names is unknown.
 * @param {string} firstName The value of the first name
 * @param {string} lastName The value of the last name
 * @returns a display ready field to print as a 'name'
 */
export const getFullName = (firstName, lastName) => {
  if (!firstName && !lastName) {
    return 'Unknown Name'
  } else if (!firstName) {
    return `Unknown ${lastName}`
  } else if (!lastName) {
    return firstName
  } else {
    return `${firstName} ${lastName}`
  }
}
export const charRestriction = (value) => {
  return value.replace(/[`=[\]/&/\\#|^+()$~%":;,*?<>{}!0123456789@]/g, '')
}

/**
 * Uses a 5ms delay to prevent performance issues with redux store updates
 * @param {Event} time variable declared in scope of the component to keep track of debounce
 * @param {Function} reduxAction the redux function to be debounced
 */
export const debounceTextInput = ({ time, reduxAction }) => {
  if (time) {
    clearTimeout(time)
  }

  time = setTimeout(() => {
    reduxAction()
  }, 5)
}
/**
 * Returns the subQuestions of a config section
 * @param {Array<Object>} config array of elements with questionIds and enable
 * @param {string} key the questionId of the parent question
 * @returns {Array} subQuestion array
 */
export const subQuestions = ({ config = [], key }) => {
  const _key = kebabCase(key)

  return config.find((item) => item.questionId === _key)?.subQuestions || []
}
/**
 * Takes a renderMap and filters out items from a subSection Object (same as renderContent)
 * @param {Object} renderMap config key mapped to the component to render { key: <Component /> }
 * @param {Array<Object>} config array of elements with questionIds and enable
 * @param {Array} content the array of components to be rendered
 * @returns {Array} if content is not given as an argument we return a new array with the components
 */
export const renderSubSection = ({ renderMap, config, content = [] }) => {
  if (!config) {
    throw new Error('No config provided to render!')
  }

  for (const key in renderMap) {
    if (Object.keys(config).length && config[kebabCase(key)].enable) {
      content.push(renderMap[key])
    }
  }

  return !content.length ? null : content
}
/**
 * Takes a renderMap and filters out the components via the config
 * @param {Object} renderMap config key mapped to the component to render { key: <Component /> }
 * @param {Array<Object>} config array of elements with questionIds and enable
 * @param {Array} content the array of components to be rendered
 * @returns {Array} if content is not given as an argument we return a new array with the components
 */
export const renderContent = ({ renderMap, config, content = [], camelCase = true }) => {
  if (!config) {
    throw new Error('No config provided to render!')
  }

  for (const key in renderMap) {
    if (
      config.find(({ questionId, enable }) => {
        const idKey = camelCase ? convertToCamelCase(questionId, '-') : questionId

        return enable && idKey === key
      })
    ) {
      content.push(renderMap[key])
    }
  }

  return !content.length ? null : content
}

/**
 * Returns a formatted labels for a person
 * @param {Object} defaults A person object.
 * @param {Object} defaults.name Name properties, `firstName` and `lastName`.
 * @param {String} defaults.relationshipToProband The relationship string value.
 * @returns {Object: { name: string, relationship: string }}
 * Their formatted name and the relationship relative to the proband.
 **/
export function getPersonLabel({
  name: { firstName = '', lastName = '' } = {},
  relationshipToProband = '',
}) {
  const makeCapitalized = (_name) => {
    return _name.trim() ? _name[0].toUpperCase() + _name.substring(1).toLowerCase() : _name
  }

  const _firstName = makeCapitalized(firstName)
  const _lastName = makeCapitalized(lastName)
  const fullName = `${_firstName} ${_lastName}`

  relationshipToProband = relationshipToProband.replace('probands', '')

  let relationship = ''

  const makeSpaceForCamelCase = () => {
    for (let i = 0; i < relationshipToProband.length; i++) {
      const currentChar = relationshipToProband[i]
      const nextChar = relationshipToProband[i + 1]

      relationship += !relationship ? currentChar.toUpperCase() : currentChar

      if (!nextChar) {
        break
      }

      if (nextChar !== nextChar.toLowerCase()) {
        relationship += ' '
      }
    }
  }

  makeSpaceForCamelCase()

  return { name: fullName?.trim() || '', relationship }
}

/**
 * Returns the state path for person given id
 * @param {String} id Person's id
 * @returns {Object} The person object from state
 **/
export function pathToPerson(id) {
  return ['questionnaire', 'persons', id]
}

export default isValueEmpty
