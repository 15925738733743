import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson } from '../../utils/helpers'
import Chooser from '../../widgets/Chooser'

const Pronouns = ({ id }) => {
  const pronoun = useSelector((state) => get(state, [...pathToPerson(id), 'pronoun']))
  const { setPersonPronoun } = useActions()

  return (
    <Chooser
      label="What is your preferred pronoun?"
      selectedValue={pronoun}
      onChange={(value) => setPersonPronoun({ id, value })}
      options={[
        [`He/Him`, `He/Him`],
        [`She/Her`, `She/Her`],
        [`They/Them`, `They/Them`],
        [`Other`, `Other`],
        [`Unknown`, `Unknown`],
        [`Prefer not to answer`, `Prefer not to answer`],
      ]}
    />
  )
}

export default Pronouns
