import AddIcon from '@mui/icons-material/Add'
import { Button, FormLabel } from '@mui/material'
import { Container } from '@mui/system'
import { get, flatten } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { useShallowSelector } from '../../redux/reduxHooks'
import FamilyHelper from '../../utils/FamilyHelper'
import { renderContent } from '../../utils/helpers'
import MultiPersonCards from '../../widgets/MultiPersonCards'
import BreastFeeding from './BreastFeeding'
import ChildQuestions from './ChildQuestions'

const Children = () => {
  const { addChild } = useActions()
  const config =
    useSelector((state) => {
      return get(state, [
        'configuration',
        'sectionConfig',
        'your-family',
        'subSectionConfig',
        'children',
        'questions',
      ])
    }) || []
  const persons = useShallowSelector((state) => state.questionnaire.persons) || {}
  const relationships = useShallowSelector((state) => state.questionnaire.relationships) || []
  const probandId = useSelector((state) => state.questionnaire.probandId) || ''

  const [children, setChildren] = useState([])

  RemoveLoadingHook()

  useEffect(() => {
    setChildren(() => getChildPersonIds(probandId))
  }, [persons, relationships])

  return (
    <Container>
      {renderContent({
        renderMap: {
          breastFeeding: <BreastFeeding key="bf" />,
        },
        config,
      })}
      <FormLabel>Please add your biological children (if applicable)</FormLabel>
      <MultiPersonCards personIds={children} entryRenderer={childQuestions} sx={{ py: 2 }} />
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={buttonHandler}
        sx={{ width: 'fit-content' }}
      >
        Add Child
      </Button>
    </Container>
  )

  /**
   * Returns question components needed for MultiPersonCards
   * @returns {JSX}
   */
  function childQuestions(personId) {
    return <ChildQuestions personId={personId} config={config} />
  }

  /**
   * Returns a list of all children of the person specified
   * @returns {String[]}
   */
  function getChildPersonIds(id) {
    const childrenList = new FamilyHelper({ persons, relationships }).getChildren(id)

    return flatten(childrenList).map(({ id: _id }) => _id)
  }

  /**
   * Adds a new child member when button is clicked
   */
  function buttonHandler() {
    addChild({ probandId, targetPersonArray: ['proband'] })
  }
}

export { Children }
