import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import FamilyHelper from '../../utils/FamilyHelper'
import { pathToPerson } from '../../utils/helpers'
import Chooser from '../../widgets/Chooser'

const RelatedToPartner = ({ id }) => {
  const {
    setPersonPropertiesIsPresent,
    setPersonPropertiesValue,
    setPartnerConsanguinity,
    removePartnerConsanguinity,
    removePartner,
  } = useActions()
  const relatedToPartner =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find(({ type }) => type === 'isRelatedToPartner')
    }) || {}
  const hasChildren =
    useSelector((state) => new FamilyHelper(state.questionnaire).getAllChildren(id)).length > 0
  const chooserValue =
    relatedToPartner.isPresent === 'N' && relatedToPartner.value === 'Not applicable'
      ? 'NA'
      : relatedToPartner.isPresent

  return (
    <Chooser
      label="Are you related to your partner in any way?"
      options={[
        ['Y', 'Yes'],
        ['N', 'No'],
        ['NA', 'Not applicable'],
      ]}
      selectedValue={chooserValue}
      onChange={(value) => {
        if (value === chooserValue) return

        if (value === 'NA') {
          setPersonPropertiesIsPresent({ id, type: 'isRelatedToPartner', value: 'N' })
          setPersonPropertiesValue({ id, type: 'isRelatedToPartner', value: 'Not applicable' })
          if (!hasChildren) removePartner()
        } else {
          setPersonPropertiesIsPresent({ id, type: 'isRelatedToPartner', value })
          setPersonPropertiesValue({ id, type: 'isRelatedToPartner', value: null })
        }

        if (value === 'Y') {
          setPartnerConsanguinity()
        } else if (value === 'N') {
          removePartnerConsanguinity()
        }
      }}
    />
  )
}

export default RelatedToPartner
