import { createSlice } from '@reduxjs/toolkit'
import uuidv4 from 'uuid/v4'
import familyActionCreators from '../../actionCreators/controlActions/family'
import ancestryActionCreators from '../../actionCreators/stateActions/ancestry'
import cancerRiskActionCreators from '../../actionCreators/stateActions/cancerRisk'
import cancersActionCreators from '../../actionCreators/stateActions/cancers'
import conditionsActionCreators from '../../actionCreators/stateActions/conditions'
import diagnosticTestsActionCreators from '../../actionCreators/stateActions/diagnosticTests'
import geneticTestingActionCreators from '../../actionCreators/stateActions/geneticTesting'
import profileActionCreators from '../../actionCreators/stateActions/profile'
import propertyActionCreators from '../../actionCreators/stateActions/properties'
import respondentActionCreators from '../../actionCreators/stateActions/respondent'
import surgeriesActionCreators from '../../actionCreators/stateActions/surgeries'
import treatmentsActionCreators from '../../actionCreators/stateActions/treatments'

/**
 * TYPE DEFINITIONS
 *
 * Standard action to manipulate person properties
 * @typedef {{type: string, payload: {id: string, value: any}}} PersonAction
 *
 * Standard reducer for person properties
 * @typedef {(state: WritableDraft<{}>, action: PersonAction)} PersonReducer
 */

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {},
  reducers: {
    ...profileActionCreators,
    ...cancerRiskActionCreators,
    ...propertyActionCreators,
    ...cancersActionCreators,
    ...diagnosticTestsActionCreators,
    ...surgeriesActionCreators,
    ...conditionsActionCreators,
    ...geneticTestingActionCreators,
    ...treatmentsActionCreators,
    ...ancestryActionCreators,
    ...familyActionCreators,
    ...respondentActionCreators,

    /**
     * Set the entire questionnaire state from payload
     * @type {(state: WritableDraft<{}>, action: { payload: object }) => WritableDraft}
     */
    setState(_, action) {
      return action.payload
    },
    /**
     * Set isMetric
     * @type {(state: WritableDraft<{}>, action: { payload: { value: string } }) => WritableDraft}
     */
    setIsMetricMeasurementSystem(state) {
      state.isMetricMeasurementSystem = !state.isMetricMeasurementSystem
    },
    /**
     * Set notes from Additional Information
     * @type {(state: WritableDraft<{}>, action: { payload: { value: string } }) => WritableDraft}
     */
    setAdditionalInformation(state, action) {
      state.notes = action.payload.value
    },
    /**
     * Set notes from Additional Information
     * @type {(state: WritableDraft<{}>, action: { payload: { value: string } }) => WritableDraft}
     */
    setAdditionalHealthProblems(state, action) {
      state.additionalHealthProblems = action.payload.value
    },
    /**
     * Set notes from Additional Information
     * @type {(state: WritableDraft<{}>, action: { payload: { value: string } }) => WritableDraft}
     */
    setAdditionalRelevantInfo(state, action) {
      state.relevantInfo = action.payload.value
    },
    /**
     * Set additional relatives in additionalRelativeInfos[]
     * @type {(state: WritableDraft<{}>, action: { payload: { value: string } }) => WritableDraft}
     */
    setAdditionalRelatives(state, action) {
      const { additionalRelativeInfos: otherRelatives } = state
      if (!otherRelatives) {
        state.additionalRelativeInfos = [action.payload.value]
      } else {
        state.additionalRelativeInfos.push(action.payload.value)
      }
    },
    /**
     * Remove additional relatives from additionalRelativeInfos[]
     * @type {(state: WritableDraft<{}>, action: { payload: { index: number } }) => WritableDraft}
     */
    removeAdditionalRelatives(state, action) {
      const { id } = action.payload
      const relativeIndex = state.additionalRelativeInfos.findIndex(
        (relative) => relative.id === id,
      )
      if (relativeIndex !== -1) {
        state.additionalRelativeInfos.splice(relativeIndex, 1)
      }
    },
    /**
     * Set consents.granted [N,Y,U] given the consent type
     * @type {(state: WritableDraft<{}>, action: { payload:
     *  { value: string, type: string }
     * }) => WritableDraft}
     */
    setConsentsGranted(state, action) {
      const { type, value } = action.payload
      const index = consentsIndex(state, type)

      state.consents[index].granted = value
    },
    /**
     * Set consents.excludedPersons [{personDTO}] given the consent type
     * @type {(state: WritableDraft<{}>, action: { payload:
     *  { value: string, type: string }
     * }) => WritableDraft}
     */
    setConsentsExcludedPersons(state, action) {
      const { type, value } = action.payload
      const index = consentsIndex(state, type)

      state.consents[index].excludedPersons = value
    },
    /**
     * Remove consent given the consent type
     * @type {(state: WritableDraft<{}>, action: { payload:
     *  { type: string }
     * }) => WritableDraft}
     */
    removeConsents(state, action) {
      const { type } = action.payload
      const { consents } = state
      const index = consents.findIndex((consent) => consent.type === type)

      if (index !== -1) {
        state.consents = consents.toSpliced(index, 1)
      }
    },
    /**
     * Set consent followup for telephone
     * @type {(state: WritableDraft<{}>, action: { payload:
     *  { value: string }
     * }) => WritableDraft}
     */
    setConsentsFollowUp(state, action) {
      const { value } = action.payload
      const index = consentsIndex(state, 'followUpConsent')

      Object.assign(state.consents[index], { type: 'followUpConsent', granted: value })
    },
    /**
     * Set consent for voice mails
     * @type {(state: WritableDraft<{}>, action: { payload:
     *  { value: string }
     * }) => WritableDraft}
     */
    setConsentsVoicemail(state, action) {
      const { value } = action.payload
      const index = consentsIndex(state, 'voicemailConsent')

      Object.assign(state.consents[index], { type: 'voicemailConsent', granted: value })
    },
    /**
     * Set answerInformationDTO object { id, questionId, answer, largeAnswer }
     * @type {(state: WritableDraft<{}>, action: { payload:
     *  { questionId: string, value: string }
     * }) => WritableDraft}
     */
    setAnswerInformation(state, action) {
      const { questionId, value } = action.payload

      if (!state.answerInformations) {
        state.answerInformations = []
      }

      const index = state.answerInformations.findIndex((item) => item.questionId === questionId)

      if (index === -1) {
        state.answerInformations.push({ id: uuidv4(), questionId, answer: value })
      } else {
        state.answerInformations[index].answer = value
      }
    },
    /**
     * Remove answerInformationDTO object given questionId
     * @type {(state: WritableDraft<{}>, action: {
     *  payload: { questionId: string }
     * }) => WritableDraft}
     */
    removeAnswerInformation(state, action) {
      const { questionId } = action.payload
      const index = state.answerInformations.findIndex((item) => item.questionId === questionId)

      if (index === -1) {
        state.answerInformations.splice(index, 1)
      }
    },
  },
})
/**
 * Check if consents exist and return the index of the object in the array
 * @type {(state: WritableDraft<{}>, action: { payload: { value: string } }) => WritableDraft}
 */
const consentsIndex = (state, type) => {
  // consent list is empty
  if (!state.consents?.length) {
    state.consents = [{ id: uuidv4(), type }]

    return 0
  }

  const index = state.consents.findIndex((consent) => consent.type === type)

  // add new consent to list
  if (index === -1) {
    state.consents.push({ id: uuidv4(), type })

    return state.consents.length - 1
  }

  return index
}

const { actions, reducer } = questionnaireSlice

export const {
  setState,
  setIsMetricMeasurementSystem,
  removePersonProperty,
  setPersonFirstName,
  setPersonLastName,
  setPersonLastNameAtBirth,
  setPersonPreferredName,
  setPersonPreviousFirstName,
  setPersonPreviousLastName,
  setPersonDateOfBirth,
  setPersonRelationshipToProband,
  setPersonSex,
  setPersonPronoun,
  setPersonGenderIdentity,
  setPersonAgeAtMenarche,
  setPersonPreviousBreastBiopsies,
  setPersonBreastBiopsyResult,
  setPersonWeight,
  setPersonHeight,
  setPersonMenopauseStatus,
  setPersonAgeAtMenopause,
  setPersonHrtUsage,
  setPersonHrtType,
  setPersonHrtLengthOfUse,
  setPersonHrtYears,
  setPersonOralContraceptiveUsage,
  setPersonOralContraceptiveUsageYears,
  setPersonConsumesAlcohol,
  setPersonTypeOfDrink,
  setPersonDrinkingFrequency,
  setPersonDrinkAmount,
  setPersonGeneTests,
  removePersonGeneTests,
  removeAllPersonGeneTests,
  setPersonSmoking,
  setPersonAvgCigarettes,
  setPersonSmokingYears,
  removePersonCancerRiskProperty,
  setPersonExtendedCancerRisk,
  removePersonExtendedCancerRisk,
  setPersonPropertiesIsPresent,
  removePersonPropertiesList,
  removePersonPropertiesListFromAll,
  setPersonFITStatus,
  removePersonFITStatus,
  setPersonColonoscopyStatus,
  removePersonColonoscopyStatus,
  setPersonBowelPolyps,
  setPersonBowelPolypsCount,
  setPersonBreastMammogramStatus,
  removePersonBreastMammogramStatus,
  setPersonProstateBiopsy,
  removePersonProstateBiopsy,
  setPersonElevatedPSA,
  removePersonElevatedPSA,
  removePersonDiagnosticTests,
  setPersonSurgeries,
  removePersonSurgeries,
  setPersonConditions,
  setPersonConditionQualifiers,
  removePersonConditionQualifiers,
  setPersonConditionIsPresent,
  setPersonConditionNotes,
  setPersonConditionTreatments,
  removePersonConditions,
  removePersonConditionTreatments,
  removePersonHeartConditions,
  setPersonGeneticTesting,
  removePersonGeneticTesting,
  setPersonAdopted,
  removePersonAdopted,
  setPersonLifeStatus,
  removePersonLifeStatus,
  setPersonAddress,
  removePersonAddresses,
  addPersonEmail,
  setPersonEmail,
  setPersonEmailUse,
  setPersonEmailPreferred,
  addPersonTelephone,
  setPersonTelephone,
  setPersonTelephoneUse,
  setPersonTelephonePreferred,
  removePersonContact,
  setPersonGeneticIdentifier,
  removePersonGeneticIdentifier,
  removePersonSexSpecificQuestions,
  setPersonCancers,
  removePersonCancers,
  setPersonCancersQualifiers,
  removePersonCancersQualifiers,
  setPersonTreatments,
  removePersonTreatments,
  addChild,
  addMiscarriage,
  addUnbornChild,
  removePersonNameProperty,
  setParentsAncestries,
  setPartnershipProps,
  setAshkenaziInheritance,
  setParentsRelationships,
  setPartnerConsanguinity,
  removePartnerConsanguinity,
  removePartner,
  addPerson,
  removePerson,
  setSiblings,
  setHalfSiblingSharedParentType,
  setPersonPropertiesValue,
  setTwinRelationship,
  resolveParent,
  removeRelativeGeneticTesting,
  removeGenderIdentity,
  setAdditionalInformation,
  setAdditionalHealthProblems,
  setAdditionalRelevantInfo,
  setAdditionalRelatives,
  removeAdditionalRelatives,
  setRespondentPerson,
  removeRespondentPerson,
  setRespondentUser,
  setRespondentNotes,
  setRespondentIsFamilyMember,
  removeRespondentIsFamilyMember,
  setConsentsGranted,
  setConsentsExcludedPersons,
  setAnswerInformation,
  removeAnswerInformation,
  removeConsents,
  setConsentsFollowUp,
  setConsentsVoicemail,
} = actions

export default reducer
