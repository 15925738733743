import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson, renderContent, subQuestions } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import { SubQuestionLabel } from '../../widgets/FormLabel'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'
import { PhoneConsent, VoicemailConsent } from './ContactConsents'

// from ContactPointDTO
const USE = {
  HOME: 'home',
  WORK: 'work',
  MOBILE: 'mobile',
  TEMP: 'temp',
}

const ContactPoint = ({
  id,
  label,
  handleUse,
  handleValue,
  handlePreferred,
  removePersonContact,
  contact,
  uses = [],
}) => {
  const { id: propertyId, use, value, preferred, type } = contact

  return (
    <Card elevation={2} sx={{ borderRadius: 2 }} role="region" aria-label={type + ' details'}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, flexGrow: 1 }}>
          <TextFieldQuestion
            label={label}
            value={value}
            handleChange={(value) => handleValue({ id, propertyId, value })}
            multiline={false}
            subQuestion
            sx={{ flexGrow: 1 }}
          />
          <FormControl sx={{ width: '9rem' }}>
            <SubQuestionLabel>Contact Type:</SubQuestionLabel>
            <Select
              value={use}
              onChange={({ target: { value } }) => handleUse({ id, propertyId, value })}
              inputProps={{ 'aria-label': 'select menu for contact type' }}
            >
              {uses.map((useType) => {
                return (
                  <MenuItem key={useType} value={useType}>
                    {useType}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            key="preferred-contact"
            label="Preferred Contact"
            control={
              <Checkbox
                checked={preferred}
                onChange={({ target: { checked: value } }) =>
                  handlePreferred({ id, propertyId, value })
                }
              />
            }
            sx={{ width: '100%' }}
          />
        </Box>
        <IconButton
          color="error"
          onClick={() => removePersonContact({ id, propertyId })}
          aria-label={'delete ' + type}
          sx={{ height: 'fit-content' }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </CardContent>
    </Card>
  )
}

const ContactQuestion = ({
  id,
  addContact,
  label,
  buttonLabel,
  type,
  handleUse,
  handleValue,
  handlePreferred,
  uses,
  config,
}) => {
  const { removePersonContact, removeConsents } = useActions()
  const contacts =
    useSelector((state) => {
      const contacts = get(state, [...pathToPerson(id), 'contactPoints']) || []

      return contacts.filter((contact) => contact.type === type)
    }) || []
  const displayConsents = contacts.length > 0

  useEffect(() => {
    if (type === 'telephone' && !displayConsents) {
      removeConsents({ type: 'followUpConsent' })
      removeConsents({ type: 'voicemailConsent' })
    }
  }, [displayConsents])

  return (
    <Container sx={{ gap: 2 }}>
      <FormLabel>{label}</FormLabel>
      <FollowUpQuestions>
        {contacts.map((contact) => (
          <ContactPoint
            key={contact.id}
            {...{
              id,
              label,
              handleUse,
              handleValue,
              handlePreferred,
              removePersonContact,
              contact,
              uses,
            }}
          />
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={addContact}
          sx={{ width: 'fit-content' }}
          variant="contained"
        >
          {buttonLabel}
        </Button>
        {type === 'telephone'
          ? renderContent({
              renderMap: {
                followUpConsent: <PhoneConsent key="fuc" display={displayConsents} />,
                voicemailConsent: <VoicemailConsent key="vmc" display={displayConsents} />,
              },
              config,
              camelCase: false,
            })
          : null}
      </FollowUpQuestions>
    </Container>
  )
}

const Emails = ({ id }) => {
  const { addPersonEmail, setPersonEmailUse, setPersonEmail, setPersonEmailPreferred } =
    useActions()

  return (
    <ContactQuestion
      label="Email(s)"
      buttonLabel="Add Email"
      type="email"
      addContact={() => addPersonEmail({ id })}
      id={id}
      handleUse={setPersonEmailUse}
      handleValue={setPersonEmail}
      handlePreferred={setPersonEmailPreferred}
      uses={[USE.HOME, USE.WORK]}
    />
  )
}

const PhoneNumbers = ({ id }) => {
  const {
    addPersonTelephone,
    setPersonTelephoneUse,
    setPersonTelephone,
    setPersonTelephonePreferred,
  } = useActions()
  const config = useSelector((state) => {
    const baseConfig = get(state, [
      'configuration',
      'sectionConfig',
      'your-information',
      'subSectionConfig',
      'demographics',
      'questions',
    ])

    return subQuestions({ config: baseConfig, key: 'telephone' })
  })

  return (
    <ContactQuestion
      label="Phone Number(s)"
      buttonLabel="Add Phone Number"
      type="telephone"
      addContact={() => addPersonTelephone({ id })}
      id={id}
      handleUse={setPersonTelephoneUse}
      handleValue={setPersonTelephone}
      handlePreferred={setPersonTelephonePreferred}
      uses={[USE.HOME, USE.WORK, USE.MOBILE, USE.TEMP]}
      config={config}
    />
  )
}

export { Emails, PhoneNumbers }
