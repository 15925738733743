import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

/* For all miscellaneous questions using the AnswerInformationDTO */

const MiscellaneousQuestion = ({ label, questionId }) => {
  const question =
    useSelector((state) => {
      const list = state.questionnaire.answerInformations || []

      return list.find((item) => item.questionId === questionId)
    }) || {}
  const { setAnswerInformation } = useActions()

  return (
    <TextFieldQuestion
      fullWidth
      label={label}
      value={question.answer || ''}
      handleChange={(value) => setAnswerInformation({ questionId, value })}
    />
  )
}

export default MiscellaneousQuestion
