import { Container } from '@mui/material'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { renderContent, subQuestions } from '../../utils/helpers'
import CollapsibleQuestion from '../../widgets/CollapsibleQuestion'
import DateOfBirth from '../../widgets/DateOfBirth'
import FullName, { PreviousName } from '../../widgets/FullName'
import GenderIdentity from '../../widgets/GenderIdentity'
import LifeStatus from '../../widgets/LifeStatus'
import MeasurementsSection from '../../widgets/MeasurementsSection'
import SexAssignedAtBirth from '../../widgets/SexAssignedAtBirth'
import Pronouns from '../GeneralHealth/Pronouns'
import Addresses from './Addresses'
import Adoption from './Adoption'
import { Emails, PhoneNumbers } from './ContactPoints'
import Twins from './Twins'

function Person({ targetPerson = '', resolvePersonId = undefined }) {
  const [personId, setPersonId] = useState('')

  const { removePersonPropertiesListFromAll, removePersonSexSpecificQuestions } = useActions()
  const localization = useLocalization()
  const probandId = useSelector((state) => state.questionnaire.probandId) || ''
  const sectionConfig = useSelector((state) => state.configuration.sectionConfig) || {}
  const isProband = targetPerson === 'proband'

  const configStep = () => {
    const enableFamilyCheck = (targetFamily, targetPerson) =>
      sectionConfig[targetFamily].enable
        ? sectionConfig[targetFamily].subSectionConfig[targetPerson]
        : {}

    switch (targetPerson) {
      case 'proband':
        return get(sectionConfig, ['your-information', 'subSectionConfig', 'demographics'])
      case 'mother':
      case 'father':
        return enableFamilyCheck('your-family', targetPerson)
      case 'mat-gmother':
        return enableFamilyCheck('mothers-family', 'maternal-grandmother')
      case 'mat-gfather':
        return enableFamilyCheck('mothers-family', 'maternal-grandfather')
      case 'pat-gmother':
        return enableFamilyCheck('fathers-family', 'paternal-grandfather')
      case 'pat-gfather':
        return enableFamilyCheck('fathers-family', 'paternal-grandfather')
      default:
        return {}
    }
  }
  const config = configStep().questions

  const renderMap = {
    name: [
      <FullName
        key="full-name"
        id={personId}
        isProband={isProband}
        localization={localization}
        config={subQuestions({ config, key: 'name' })}
      />,
    ],
    namePrevious: (
      <CollapsibleQuestion key="pre-name" label="Has your name changed?">
        <PreviousName id={personId} localization={localization} />
      </CollapsibleQuestion>
    ),
    dateOfBirth: <DateOfBirth key="personDOB" id={personId} />,
    sexAtBirth: isProband ? (
      <SexAssignedAtBirth
        key="SexAssignedAtBirth"
        label="Sex assigned at birth *"
        id={personId}
        clearSexSpecificQuestions={isProband && clearSexSpecificQuestions}
      />
    ) : null,
    pronounPreferred: <Pronouns key="Pronouns" id={personId} />,
    notCisgender: <GenderIdentity key="GenderIdentity" id={personId} />,
    measurement: (
      <MeasurementsSection
        key="MeasurementsSection"
        id={probandId}
        config={subQuestions({ config, key: 'measurement' })}
      />
    ),
    twin: <Twins key="Twins" id={probandId} />,
    addressStreet: <Addresses key="address" id={personId} />,
    telephone: <Emails key="Emails" id={probandId} />,
    email: <PhoneNumbers key="PhoneNumbers" id={probandId} />,
    adopted: renderAdoptionInfo(),
    fatherAdopted: renderAdoptionInfo(),
    motherAdopted: renderAdoptionInfo(),
    lifeStatus: (
      <LifeStatus
        key="LifeStatus"
        personId={personId}
        isProband={isProband}
        config={subQuestions({ config, key: 'lifeStatus' })}
      />
    ),
  }

  const content = []
  renderContent({ renderMap, config, content })
  RemoveLoadingHook()

  useEffect(() => {
    setPersonId(resolvePersonId ? resolvePersonId(true) : probandId)
  }, [resolvePersonId])

  return <Container sx={{ gap: 4, pb: 8 }}>{content}</Container>

  function renderAdoptionInfo() {
    return <Adoption key="Adoption" id={personId} targetPerson={targetPerson} />
  }

  /**
   * Reset all properties that are sex specific
   */
  function clearSexSpecificQuestions() {
    removePersonPropertiesListFromAll({ type: 'twin' })
    removePersonSexSpecificQuestions({ id: probandId })
  }
}

export default Person
