import { FormControl, FormLabel } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const AddressInput = ({ id, label, addressProperty, handleChange }) => {
  const addressValue = useSelector((state) => {
    const addresses = get(state, [...pathToPerson(id), 'addresses']) || []
    const address = addresses.length > 0 && addresses[0]

    return address ? address[addressProperty] : undefined
  })

  return (
    <TextFieldQuestion
      label={label}
      value={addressValue || ''}
      handleChange={handleChange}
      subQuestion
      multiline={false}
      sx={{ minWidth: '280px', flexBasis: 'calc(50% - 24px)' }}
    />
  )
}

const Addresses = ({ id }) => {
  const localization = useLocalization()
  const { setPersonAddress } = useActions()

  if (!id) return

  return (
    <FormControl>
      <FormLabel>{getLabel(localization, 'person.address')}</FormLabel>
      <FollowUpQuestions sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 3 }}>
        <AddressInput
          id={id}
          label={getLabel(localization, 'person.address.addressLine1')}
          addressProperty="addressLine1"
          handleChange={(value) => setPersonAddress({ id, value: { addressLine1: value } })}
        />
        <AddressInput
          id={id}
          label={getLabel(localization, 'person.address.addressLine2')}
          addressProperty="addressLine2"
          handleChange={(value) => setPersonAddress({ id, value: { addressLine2: value } })}
        />
        <AddressInput
          id={id}
          label={getLabel(localization, 'person.address.city')}
          addressProperty="city"
          handleChange={(value) => setPersonAddress({ id, value: { city: value } })}
        />
        <AddressInput
          id={id}
          label={getLabel(localization, 'person.address.country')}
          addressProperty="country"
          handleChange={(value) => setPersonAddress({ id, value: { country: value } })}
        />
        <AddressInput
          id={id}
          label={getLabel(localization, 'person.address.postalCode')}
          addressProperty="postalCode"
          handleChange={(value) => setPersonAddress({ id, value: { postalCode: value } })}
        />
      </FollowUpQuestions>
    </FormControl>
  )
}

export default Addresses
