import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormLabel,
  IconButton,
} from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import FamilyHelper from '../../utils/FamilyHelper'
import { pathToPerson, renderContent } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import IntegerDetails from '../../widgets/IntegerDetails'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const MiscarriageQuestions = ({ personId, config }) => {
  const { setPersonLifeStatus, removePerson } = useActions()
  const lifeStatus =
    useSelector((state) => get(state, [...pathToPerson(personId), 'lifeStatus'])) || {}
  const { gestationAge, causeOfDeath } = lifeStatus

  return (
    <Card elevation={2} sx={{ borderRadius: 2 }} role="region" aria-label="new person details">
      <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Container>
          {renderContent({
            renderMap: {
              patientMiscarriageWeeks: (
                <FormControl key="miscarriageWeeks">
                  <FormLabel>At how many weeks?</FormLabel>
                  <IntegerDetails
                    value={gestationAge || ''}
                    onChange={(value) =>
                      setPersonLifeStatus({ id: personId, type: 'gestationAge', value })
                    }
                  />
                </FormControl>
              ),
            },
            config,
          })}
          <TextFieldQuestion
            key="miscarriageNotes"
            label="Additional notes"
            value={causeOfDeath || ''}
            handleChange={(value) => {
              setPersonLifeStatus({ id: personId, type: 'causeOfDeath', value })
            }}
          />
        </Container>
        <IconButton
          color="error"
          onClick={(e) => {
            e.stopPropagation()
            removePerson({ personId })
          }}
          aria-label="remove miscarriage"
        >
          <DeleteForeverIcon />
        </IconButton>
      </CardContent>
    </Card>
  )
}

const Miscarriage = ({ id, targetPersonArray, config }) => {
  const { addMiscarriage, setPersonPropertiesIsPresent, removePerson } = useActions()
  const miscarriages = useSelector((state) => {
    let children = new FamilyHelper(state.questionnaire)
      .getAllChildren(id)
      .map((child) => child.getDetailsObj())
    children = children.filter((child) => child.lifeStatus?.lifeStatusOption === 'miscarriage')

    return children.map((person) => person.id)
  })
  const hasHadMiscarriage =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find(({ type }) => type === 'hasHadMiscarriage')
    }) || {}

  return (
    <ConditionalSection
      label="Have you or your partner had a miscarriage?"
      conditionState={hasHadMiscarriage?.isPresent}
      onConditionStateChange={(value) => {
        if (value === hasHadMiscarriage?.isPresent) return

        setPersonPropertiesIsPresent({ id, type: 'hasHadMiscarriage', value })
      }}
      showWhenCondition={'Y'}
      cleanUpChildQuestions={() => {
        if (miscarriages) {
          miscarriages.forEach((id) => removePerson({ personId: id }))
        }
      }}
      orderSchema={['Y', 'N']}
    >
      <FormControl>
        <FormLabel>Miscarriage Details</FormLabel>
        <Container sx={{ py: 2 }}>
          {miscarriages.map((personId) => (
            <MiscarriageQuestions key={personId} personId={personId} config={config} />
          ))}
        </Container>
        <Button
          startIcon={<AddIcon />}
          onClick={() => addMiscarriage({ id, targetPersonArray })}
          sx={{ width: 'fit-content' }}
          variant="contained"
        >
          Add Miscarriage
        </Button>
      </FormControl>
    </ConditionalSection>
  )
}

export default Miscarriage
