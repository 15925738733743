import { combineReducers, configureStore } from '@reduxjs/toolkit'
import configurationReducer from './slices/configurationSlice'
import hpoVocabularyReducer from './slices/hpoVocabularySlice'
import localizationReducer from './slices/localizationSlice'
import metricReducer from './slices/metricSlice'
import questionnaireReducer from './slices/questionnaireSlice'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Serialized value is not recommended by Redux
      // but our familyModel needs to send the callback function.
      // eslint-disable-next-line spellcheck/spell-checker
      serializableCheck: {
        ignoredActions: [
          'questionnaire/setPersonFirstName',
          'questionnaire/setPersonLastName',
          'questionnaire/setPersonLastNameAtBirth',
          'questionnaire/setPersonAdopted',
          'questionnaire/setPersonLifeStatus',
          'questionnaire/setPersonDateOfBirth',
          'questionnaire/setSiblings',
          'questionnaire/setTwinRelationship',
          'questionnaire/resolveParent',
          'questionnaire/removePersonLifeStatus',
        ],
      },
    }),
  reducer: combineReducers({
    questionnaire: questionnaireReducer,
    localization: localizationReducer,
    metrics: metricReducer,
    configuration: configurationReducer,
    hpoVocabulary: hpoVocabularyReducer,
  }),
})
