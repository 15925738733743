/* eslint-disable prefer-regex-literals */
import produce from 'immer'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import FamilyHelper, { RelationshipPaths, RelationshipTypes } from './FamilyHelper.js'
import isValueEmpty from './helpers'

/**
 * Helper class to handle state management for the `Questionnaire` component.
 * The purpose of this helper is to avoid mixing component rendering code
 * with state manipulation code, and to keep the Questionnaire component file from
 * becoming too big.
 *
 * A few ground rules:
 *  - For empty values, the backend may either leave the keys undefined, or send null.
 *    This code should properly handle both of those possibilities.
 *  - When we set values to empty, we prefer to unset them
 *    (i.e. set them to undefined) over setting them to null.
 *  - null represents "the user has not entered a value for this" OR "the user has
 *    entered an empty value for this".
 *    There is no way to distinguish between them.
 */
class QuestionnaireStateManager {
  constructor(questionnaireInstance) {
    // cpt is short form for "component instance"
    this.cpt = questionnaireInstance
    // shortcut for easier use
    this.setState = this.cpt.setState.bind(this.cpt)

    _.bindAll(this, ['getPersonById', 'getProband'])
  }

  getState() {
    return this.cpt.state
  }

  getQuestionnaireType() {
    return this.cpt?.state?.questionnaireType
  }

  isQuestionnaireType(type) {
    return this.cpt?.state?.questionnaireType === type
  }

  isCancerQuestionnaireType() {
    return this.isQuestionnaireType(QuestionnaireStateManager.questionnaireTypes.CANCER)
  }

  isSimpleQuestionnaireType() {
    return this.isQuestionnaireType(QuestionnaireStateManager.questionnaireTypes.SIMPLE)
  }

  getCountryCode() {
    return this.cpt.state.countryCode
  }

  /** Person helpers */

  getPersonById(id) {
    return QuestionnaireStateManager.getPersonById(id, this.cpt.state)
  }

  getProband() {
    return QuestionnaireStateManager.getProband(this.cpt.state)
  }

  /**
   * Create, update, and delete the app state value on the basis of
   * a specific `pathKey` and `pathValue` criteria.
   * @param {Object} defaults The parameter to create, update and delete a value.
   * @param {String} defaults.personId The selected person id.
   * @param {Array.<String>} defaults.path Search criteria to find the property to be updated.
   * Sometimes the target property should be found on the basis of both `pathKey` and `pathValue`.
   * @param {String | undefined} defaults.field The key name for new value.
   * @param {String | Number | Boolean | Object | undefined} defaults.newFieldValue
   * The value to be set in `defaults.field` above. It will run the delete function
   * if the `defaults.newField` is `undefined`.
   * @param {String | undefined} defaults.id The id for `conditions`.
   * @param {String | undefined} defaults.label The question for `conditions`.
   */
  updatePersonValue({
    personId,
    path,
    field = undefined,
    targetIndex = undefined,
    newFieldValue = undefined,
  }) {
    const _state = { ...this.getState() }
    const _persons = { ..._state.persons }
    // Implements `Array` because the nested key can be available
    // in the future.
    const [pathKey] = path

    // Fully deep clone for the target properties to prevent read-only issue
    // from `Immer` lib.
    let _person = {
      ..._persons[personId],
    }

    if (typeof _person[pathKey] === 'string') {
      //* String values properties (1st tier)
      //* eg. person.genderIdentity = 'F'

      if (newFieldValue === undefined) {
        // remove value
        delete _person[pathKey]
      } else {
        // change value
        _person[pathKey] = newFieldValue || null
      }
    } else if (Array.isArray(_person[pathKey])) {
      //* Array type properties (1st tier)
      //* eg. person.conditions = [{...}, ...]

      // To avoid read-only issue in ArrayType.
      _person = {
        ..._person,
        [pathKey]: [..._person[pathKey]],
      }
      if (targetIndex === undefined && newFieldValue === undefined) {
        // reset entire array
        _person[pathKey] = []
      } else if (newFieldValue === undefined) {
        // remove specific item from array
        _person[pathKey].splice(targetIndex, 1)
      } else {
        if (targetIndex < 0) {
          // add new item to array
          _person[pathKey] = [..._person[pathKey], newFieldValue]
        } else {
          // replace specific item in array
          _person[pathKey].splice(targetIndex, 1, newFieldValue)
        }
      }
    } else {
      //* Object properties (1st tier) OR adding new property
      //* eg. person.geneticTesting = {...}

      if (!field && targetIndex === undefined) {
        // create NEW property (any type) or reset object type
        _person[pathKey] = newFieldValue || {}
      } else {
        //* Nested Array type (2nd tier)
        //* eg. person.cancerRiskData.alcoholConsumptionDetails = [{...}, ...]

        let arrayTypeValue = []

        if (targetIndex >= 0 && _person[pathKey][field]) {
          arrayTypeValue = [..._person[pathKey][field]]
        }

        // adding object to nested array
        arrayTypeValue[targetIndex === -1 ? 0 : targetIndex] = newFieldValue

        _person = {
          ..._person,
          [pathKey]: {
            ..._person[pathKey],
            // `targetIndex >= -1` means that it is an array value.
            // Otherwise we can implements `newFieldValue` which is literal object.
            [field]: targetIndex >= -1 ? arrayTypeValue : newFieldValue,
          },
        }
      }
    }

    this.setState(() => ({
      ..._state,
      persons: {
        ..._state.persons,
        [personId]: _person,
      },
    }))
  }

  /**
   * Sets the value at the given path in the given Person object.
   * This function should only be used to set string, number, or boolean, NOT object.
   *
   * This function might seem simple, but it actually has some special logic
   * to handle creation of objects within arrays. This is explained below.
   *
   * `path` is a lodash-style object path descriptor that can contain
   * object key and array index references, e.g.
   * a.b[0].c.d[1].e
   *
   * This function, however, extends the path descriptor further
   * to allow substitution of variable indices using an
   * array search. This is what the `arrayIdxSearchCriteria` argument is used for.
   *
   * A `path` can be used along with `arrayIdxSearchCriteria` with substitution values like
   * in this example:
   * `path`: a.b[bIndex].c.d[dIndex].e
   * `arrayIdxSearchCriteria`: {
   *    bIndex: { id: 1 },
   *    dIndex: { id: 2 },
   * }
   * In that example, the array at path a.b will be searched for an object
   * that has { id: 1 }. If it is found, the array
   * index of the matched object will be substituted for `bIndex`.
   * If it is not, a new object will be created, added to
   * the array, and its index will be substituted for `bIndex`.
   * The same will be done for `dIndex`.
   *
   * If a new object needs to be created:
   * 1) `QuestionnaireStateManager.PersonConstructors` will first be checked for a constructor
   * function, at the key
   * corresponding to the array's path. That constructor will be called to create a new object.
   * 2) The search criteria will be merged into the newly constructed object.
   *
   * @param {string|function} personId The ID of the Person object OR a function
   * that returns the ID of the Person
   *    object, which will be called with the state object as an argument
   * @param {string} path The path within the object, e.g. lifeStatus.alive
   * @param {*} value The value to set at the requested path.
   *    an empty value to unset the value at the requested path
   * @param {object} arrayIdxSearchCriteria An object holding search criteria
   * for substitution values in the `path`
   *    argument. (See above for more details)
   */
  setPersonValue(personId, path, value, arrayIdxSearchCriteria) {
    this.setState(
      produce((draftState) => {
        const resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId
        const person = QuestionnaireStateManager.getPersonById(resolvedPersonId, draftState)
        const resolvedPath = this.resolvePath(person, path, arrayIdxSearchCriteria, true)

        if (isValueEmpty(value)) {
          _.unset(person, resolvedPath)
        } else {
          // lodash _.set() should be for primitive types only
          // using it to set objects may cause side effects (but it works for now)
          _.set(person, resolvedPath, value)
        }
      }),
    )
  }

  /**
   * Resolves substitution values within a path string, optionally
   * also creating new arrays and objects along the way.
   * See `setPersonValue` for the motivation behind doing this.
   * @param {object} obj The object within which to resolve the path
   *    If `create` is `true`, then new objects/arrays will also be created inside this object.
   * @param {string} path The path to resolve
   * @param {object} arrayIdxSearchCriteria An object holding search criteria
   * for substitution values in the `path`
   *    argument (see docs for `setPersonValue`)
   * @param {boolean} create Should new arrays and objects be created in case they don't exist?
   * @returns {string} the path string with all substitution values substituted
   */
  resolvePath(obj, path, arrayIdxSearchCriteria, create) {
    if (_.isEmpty(arrayIdxSearchCriteria)) {
      return path
    }

    let resolvedPath = path
    let match, idxLabel, resolvedIdx, targetArr, targetArrPath

    // Still does not fully understand why `while` statement has been
    // implemented even though we do not have anything to grab multiple
    // indexes. Also, the previous regex (/\[([^\]\d]*)\]/g) consumes too much performance
    // So the value should be switched for a workaround and then need
    // to block eslint to pass sonarCloud.
    // [TODO] simply this function. (No reason it has high complexity like this.)
    // eslint-disable-next-line no-useless-escape
    while ((match = new RegExp(/\[[^[\]]*\]/g).exec(resolvedPath))) {
      idxLabel = match[0].substring(1, match[0].length - 1)

      if (!isNaN(idxLabel)) {
        break
      }

      targetArrPath = resolvedPath.substring(0, match.index)
      targetArr = _.get(obj, targetArrPath)

      if (!targetArr) {
        targetArr = []
      }

      const searchCriteriaObj = arrayIdxSearchCriteria[idxLabel]
      resolvedIdx = _.findIndex(targetArr, searchCriteriaObj)

      if (resolvedIdx === -1) {
        resolvedIdx = targetArr.length

        if (create && QuestionnaireStateManager.PersonConstructors[targetArrPath]) {
          const constructFn = QuestionnaireStateManager.PersonConstructors[targetArrPath]
          const newObj = constructFn(obj)
          // merge the search criteria values into the new object
          _.assign(newObj, searchCriteriaObj)
          _.set(obj, targetArrPath + '[' + resolvedIdx + ']', newObj)
        }
      }

      resolvedPath = resolvedPath.replace('[' + idxLabel + ']', '[' + resolvedIdx + ']')
    }

    return resolvedPath
  }

  /**
   * Returns the value at the given path in the given Person object.
   * @param {string} personId The ID of the Person object
   * @param {string} path The path within the object, e.g. lifeStatus.alive
   * @returns the requested value
   *    undefined if the value is not found
   */
  getPersonValue(personId, path, arrayIdxSearchCriteria) {
    if (!personId) {
      return undefined
    }

    const person = this.getPersonById(personId)
    const resolvedPath = this.resolvePath(person, path, arrayIdxSearchCriteria)

    return _.get(person, resolvedPath)
  }

  /**
   * Adds a value to the array in the person object at the given path.
   * If the array does not exist, it is created.
   * @param {string} personId The ID of the Person object
   * @param {string} path The path within the object, e.g. ancestry.paternal
   * @param {string} value The value to add
   */
  addToPersonArray(personId, path, value) {
    this.setState(
      produce((draftState) => {
        const person = QuestionnaireStateManager.getPersonById(personId, draftState)
        let target = _.get(person, path)

        if (!target || !_.isArray(target)) {
          target = []
        }

        target.push(value)
        _.set(person, path, target)
      }),
    )
  }

  /**
   * Removes a value from the array in the person object at the given path.
   * @param {string} personId The ID of the Person object
   * @param {string} path The path within the object, e.g. ancestry.paternal
   * @param {string} value The value to remove
   *    null or undefined to unset the value at the requested path
   */
  removeFromPersonArray(personId, path, value) {
    this.setState(
      produce((draftState) => {
        const person = QuestionnaireStateManager.getPersonById(personId, draftState)
        const target = _.get(person, path)
        if (!target || !_.isArray(target)) {
          // nothing for us to do
          return
        }

        if (value === null || value === undefined) {
          _.unset(person, path)
        } else {
          _.remove(target, (val) => val === value)
        }
      }),
    )
  }

  removeFromArrayInPath(personId, path, index) {
    this.setState(
      produce((draftState) => {
        const person = QuestionnaireStateManager.getPersonById(personId, draftState)
        const target = _.get(person, path)
        if (!target || !_.isArray(target)) {
          return // nothing for us to do
        }

        if (index !== null || index !== undefined) {
          delete target[index]
          const filterNull = target.filter((item) => item)
          _.set(person, path, filterNull)
        }
      }),
    )
  }

  /**
   * Directly modifies the state using a provided callback function.
   * This function provides more flexibility, but also
   * more opportunity for error, so it should only be used
   * when the usage falls outside of other, more specific
   * functions provided by this class.
   * @param {function} callback The callback to call with `draftState`.
   * The handler should make the desired modifications to `draftState`.
   */
  modifyState(callback) {
    this.setState(produce(callback))
  }

  /** Field-specific helpers */
  setPersonConditionValues(personId, mergeObj, searchCondition) {
    const createFn = (mergeObj) => {
      const newObj = {
        id: uuidv4(),
      }

      _.assign(newObj, mergeObj)
    }

    this.setPersonArrayObjectValues(
      personId,
      PersonFieldPaths.CONDITIONS,
      mergeObj,
      searchCondition,
      createFn,
    )
  }

  getPersonAncestryPath(side) {
    if (side === 'maternal') {
      return PersonFieldPaths.MATERNAL_ANCESTRY
    } else if (side === 'paternal') {
      return PersonFieldPaths.PATERNAL_ANCESTRY
    } else {
      return null
    }
  }

  /** Family manipulation */
  getFamilyHelper(cptState = null) {
    return new FamilyHelper(cptState || this.cpt.state)
  }

  setSiblingCount(personId, siblingSex, numberSharedParents, newCount, targetPersonArray) {
    this.setState(
      produce((draftState) => {
        const resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId

        new FamilyHelper(draftState).setSiblingCount(
          resolvedPersonId,
          siblingSex,
          numberSharedParents,
          newCount,
          targetPersonArray,
        )
      }),
    )
  }

  setHalfSiblingSharedParentType(personId, siblingId, sharedParentSex) {
    this.setState(
      produce((draftState) => {
        const resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId

        new FamilyHelper(draftState).setHalfSiblingSharedParentType(
          resolvedPersonId,
          siblingId,
          sharedParentSex,
        )
      }),
    )
  }

  setTwinRelationship(personId, probandId, relPath) {
    const TwinProperties = {
      monozygoticTwin: 'monozygoticTwin',
      dizygoticTwin: 'dizygoticTwin',
    }

    const relationshipPath = TwinProperties[relPath]

    this.setState(
      produce((draftState) => {
        const resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId
        new FamilyHelper(draftState).setTwinRelationship(
          resolvedPersonId,
          probandId,
          relationshipPath,
        )
      }),
    )
  }

  removePerson(personId) {
    this.setState(
      produce((draftState) => {
        const familyHelper = new FamilyHelper(draftState)

        if (familyHelper.doesPersonExist(personId)) {
          familyHelper.removePerson(personId)
        }
      }),
    )
  }

  setRelationshipProperty(getRelationship, propPath, propVal) {
    this.setState(
      produce((draftState) => {
        const resolvedRel = getRelationship(draftState)

        if (resolvedRel) {
          _.set(resolvedRel, propPath, propVal)
        }
      }),
    )
  }

  getPartnership(personId) {
    const relationships = this.cpt.state[StateFieldPaths.RELATIONSHIPS]
    const fatherId = this.getFamilyHelper().resolveParent(personId, 'M', true, 'probandsFather').id
    const motherId = this.getFamilyHelper().resolveParent(personId, 'F', true, 'probandsMother').id

    const partnershipRelatives = relationships.find((rel) => {
      return (
        ((_.get(rel, RelationshipPaths.SOURCE) === fatherId &&
          _.get(rel, RelationshipPaths.TARGET) === motherId) ||
          (_.get(rel, RelationshipPaths.SOURCE) === motherId &&
            _.get(rel, RelationshipPaths.TARGET) === fatherId)) &&
        _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.PARTNERSHIP
      )
    })

    return (
      partnershipRelatives ||
      this.getFamilyHelper().addRelationship(fatherId, motherId, RelationshipTypes.PARTNERSHIP, [])
    )
  }

  setPartnershipProps(personId, properties) {
    this.setState(
      produce((draftState) => {
        const familyHelper = new FamilyHelper(draftState)
        const partnership = this.getPartnership(personId, properties)

        familyHelper.removeRelationship(
          partnership.source,
          partnership.target,
          RelationshipTypes.PARTNERSHIP,
        )

        familyHelper.addRelationship(
          partnership.source,
          partnership.target,
          RelationshipTypes.PARTNERSHIP,
          properties,
        )
      }),
    )
  }
}

QuestionnaireStateManager.PersonFieldPaths = {
  ID: 'id',
  ALIVE_STATUS: 'lifeStatus.alive',
  CAUSE_OF_DEATH: 'lifeStatus.causeOfDeath',
  AGE_OF_DEATH: 'lifeStatus.ageOfDeath',
  POST_MORTEM: 'lifeStatus.postMortemPerformed',
  PATERNAL_ANCESTRY: 'ancestry.paternal',
  MATERNAL_ANCESTRY: 'ancestry.maternal',
  ANCESTRY_ASHKENAZI_JEWISH: 'ancestry.ashkenaziJewish',
  FIRST_NAME: 'name.firstName',
  LAST_NAME: 'name.lastName',
  LAST_NAME_AT_BIRTH: 'name.lastNameAtBirth',
  SEX: 'sex',
  GENDER_IDENTITY: 'genderIdentity',
  FIRST_MENSTRUAL_PERIOD: 'cancerRiskData.ageAtMenarche',
  ORAL_CONTRACEPTIVE_USAGE: 'cancerRiskData.oralContraceptiveUsage',
  MENOPAUSE_STATUS: 'cancerRiskData.menopauseStatus',
  AGE_AT_MENOPAUSE: 'cancerRiskData.ageAtMenopause',
  HRT_USAGE: 'cancerRiskData.hrtUsage',
  HRT_TYPE: 'cancerRiskData.hrtType',
  TOBACCO_USAGE: 'cancerRiskData.smoking',
  AVERAGE_CIGARETTES_DAY: 'cancerRiskData.avgCigarettes',
  TOBACCO_TOTAL_YEARS: 'cancerRiskData.smokingYears',
  ALCOHOL_USAGE: 'cancerRiskData.consumesAlcohol',
  AGE_AT_MENOPAUSE_LABEL: 'cancerRiskData.menopauseStatus.ageAtMenopause',
  HRT_TYPE_LABEL: 'cancerRiskData.hrtUsage.hrtType',
  AVERAGE_CIGARETTES_DAY_LABEL: 'cancerRiskData.smoking.avgCigarettes',
  TOBACCO_TOTAL_YEARS_LABEL: 'cancerRiskData.smoking.years',
  ALCOHOL_USAGE_LABEL: 'cancerRiskData.alcoholUse',
  ALCOHOL_AVG_MONTH_LABEL: 'cancerRiskData.alcoholUse.avgMonthlyDrinks',
  DRINKING_FRECUENCY: 'cancerRiskData.alcoholConsumptionDetails[0].drinkingFrequency',
  DRINK_AMOUNT: 'cancerRiskData.alcoholConsumptionDetails[0].drinkAmount',
  ENDOMETRIOSIS_DIAGNOSED: 'cancerRiskData.endometriosis',
  DIAGNOSTIC_TESTS: 'diagnosticTests',
  DIAGNOSTIC_TEST_TYPE: 'diagnosticTests.type',
  DIAGNOSTIC_TEST_RESULT: 'diagnosticTest.result',
  DIAGNOSTIC_TEST_LAST_PERFORMED: 'diagnosticTest.lastPerformedDate',
  DIAGNOSTIC_TEST_PERFORMED_LOCATION: 'diagnosticTest.performedAtLocation',
  SURGERIES: 'surgeries',
  SURGERY_TYPE: 'surgeries[propertyIdx].type',
  SURGERY_AGE: 'surgeries[propertyIdx].age',
  SURGERY_REASON: 'surgeries[propertyIdx].reason',
  BREAST_BIOPSY_STATUS: 'breastBiopsyStatus',
  BREAST_BIOPSY_RESULTS: 'breastBiopsyResults',
  PROSTATE_BIOPSY_STATUS: 'prostateBiopsyStatus',
  ELEVATED_PSA_STATUS: 'elevatedPSAStatus',
  GENE_TESTS: 'cancerRiskData.geneTests',
  ADOPTED_STATUS: 'adopted',
  ENDOMETRIOSIS_LABEL: 'cancerRiskData.endometriosis',
  DIAGNOSTIC_RESULT_LABEL: 'diagnosticTest.result',
  COLONOSCOPY_LABEL: 'diagnosticTest.colonoscopy',
  COLONOSCOPY_AGE_LABEL: 'diagnosticTest.colonoscopy.ageStarted',
  BREAST_MAMMOGRAM_LABEL: 'diagnosticTest.breastMammogram',
  BREAST_BIOPSY_LABEL: 'cancerRiskData.breastBiopsy',
  PROSTATE_BIOPSY_LABEL: 'diagnosticTest.prostateBiopsy',
  ELEVATED_PSA_LABEL: 'diagnosticTest.elevatedPSA',
  SURGERY_LABEL: 'surgery',
  LUMPECTOMY_LABEL: 'surgery.options.lumpectomy',
  MASTECTOMY_LABEL: 'surgery.options.mastectomy',
  OOPHORECTOMY_LABEL: 'surgery.options.oophorectomy',
  SALPINGECTOMY_LABEL: 'surgery.options.salpingectomy',
  HYSTERECTOMY_LABEL: 'surgery.options.hysterectomy',
  TUBAL_LIGATION_LABEL: 'surgery.options.tubalLigation',
  POSITIVE_GENES_LABEL: 'geneticTesting.positiveGenes',
  DATE_OF_BIRTH: 'dateOfBirth',
  RELATIONSHIP_TO_PROBAND: 'relationshipToProband',
  CONDITIONS: 'conditions',
  CONDITION_CONDITION_NAME: 'conditions[propertyIdx].conditionName',
  CONDITION_IS_PRESENT: 'conditions[propertyIdx].isPresent',
  CONDITION_TYPE: 'conditions[propertyIdx].type',
  CONDITION_ID: 'conditions[propertyIdx].id',
  CONDITION_DESCRIPTION: 'conditions[propertyIdx].description',
  FIT_STATUS_LABEL: 'diagnosticTest.fit',
  FIT_RESULT_LABEL: 'diagnosticTest.result',
  CONDITION_LABEL: 'conditions[propertyIdx].label',
  CANCERS: 'cancers',
  CANCERS_CANCER_ID: 'cancers[cancerIdx].id',
  CANCERS_CANCER_LABEL: 'cancers[cancerIdx].label',
  CANCERS_CANCER_AFFECTED: 'cancers[cancerIdx].affected',
  CANCERS_CANCER_QUALIFIERS: 'cancers[cancerIdx].qualifiers',
  GENETIC_TESTING: 'geneticTesting',
  GENETIC_TESTING_PERFORMED: 'geneticTesting.performed',
  GENETIC_TESTING_DESCRIPTION: 'geneticTesting.description',
  AGE_AT_MENARCHE: 'cancerRiskData.ageAtMenarche',
  ORAL_CONTRACEPTIVE_PILLS_USAGE: 'cancerRiskData.oralContraceptivePillsUsage',
  HEIGHT: 'cancerRiskData.height',
  WEIGHT: 'cancerRiskData.weight',
  HAS_CHILDREN: 'properties.hasChildren',
  IS_TWIN: 'properties.twin',
  SHARED_PARENT_FATHER: 'properties.sharedParent.father',
  SHARED_PARENT_MOTHER: 'properties.sharedParent.mother',
  USER_DEFINED_RELATIONSHIP: 'properties.userDefinedRelationship',
  SHARED_PARENT: 'properties.sharedParent',
  IS_ADOPTED: 'adopted',
  IS_FATHER_ADOPTED: 'adopted.father',
  IS_MOTHER_ADOPTED: 'adopted.mother',
  ARE_ALL_MATERNAL_COUSINS_ALIVE: 'properties.areAllMaternalCousinsAlive',
  ARE_ALL_PATERNAL_COUSINS_ALIVE: 'properties.areAllPaternalCousinsAlive',
  EXISTS_MATERNAL_COUSIN_WITH_HEART_PROBLEM: 'properties.existsMaternalCousinWithHeartProblem',
  EXISTS_PATERNAL_COUSIN_WITH_HEART_PROBLEM: 'properties.existsPaternalCousinWithHeartProblem',
  EXISTS_PATERNAL_EXT_RELATIVE_WITH_HEART_PROBLEM:
    'properties.existsPaternalExtRelativeWithHeartProblem',
  EXISTS_MATERNAL_EXT_RELATIVE_WITH_HEART_PROBLEM:
    'properties.existsMaternalExtRelativeWithHeartProblem',
  HAS_RELATIVE_SEEN_BY_TEAM: 'properties.hasRelativeSeenByTeam',
  HAS_BEEN_SEEN_BY_TEAM: 'properties.hasBeenSeenByTeam',
  HAS_BEEN_SEEN_BY_TEAM_LOCATION: 'properties.hasRelativeSeenByTeamLocation',
  HAS_RELATIVE_SEEN_ELSEWHERE: 'properties.hasRelativeSeenElsewhere',
  HAS_RELATIVE_SEEN_ELSEWHERE_DESC: 'properties.hasRelativeSeenElsewhereDesc',
  PROPERTIES: 'properties',
  PROPERTY_TYPE: 'properties[propertyIdx].type',
  PROPERTY_IS_PRESENT: 'properties[propertyIdx].isPresent',
  PROPERTY_VALUE: 'properties[propertyIdx].value',
  PREVIOUS_BREAST_BIOPSIES: 'cancerRiskData.previousBreastBiopsies',
  BREAST_BIOPSY_RESULT: 'cancerRiskData.breastBiopsyResult',
  BREAST_BIOPSY_RESULT_OPTIONS: 'cancerRiskData.breastBiopsy.breastBiopsyResults',
  BREAST_BIOPSY_RESULT_LABEL: 'cancerRiskData.breastBiopsy.breastBiopsyResults',
  DIAGNOSED_WITH_CANCER: 'cancer.hasCancer',
  HAS_RELATIVE_HAVE_HYSTERECTOMY: 'properties.hasRelativeHaveHysterectomy',
  HAS_RELATIVE_HAS_CANCER: 'properties.hasRelativeHasCancer',
  HAS_RELATIVE_REMOVED_OVARIES: 'properties.hasRelativeRemovedOvaries',
  PANCREATITIS_LABEL: 'conditions.pancreatitis',
  TUMOUR_LABEL: 'conditions.tumor',
  PITUITARY_LABEL: 'conditions.pituitary',
  MOLE_NEVI_LABEL: 'conditions.moles',
  POLYPS_REMOVED_LABEL: 'conditions.polyps',
  PANCREATITIS: 'options.pancreatitis',
  TUMOUR: 'options.tumor',
  PITUITARY: 'options.pituitary',
  MOLE_NEVI: 'options.moles',
  POLYPS_REMOVED: 'options.polyps',
  BLOOD_CLOT: 'options.blood-clot',
  BLOOD_CLOT_LABEL: 'conditions.blood-clot',
  ENDOMETRIOSIS: 'options.endometriosis',
  LCIS: 'options.lcis',
  TYPES_OF_CANCER: 'cancer.typeOfCancer',
  BRCA1_GENE: 'geneticTesting.positiveGenes.gene.options.brca1',
  BRCA2_GENE: 'geneticTesting.positiveGenes.gene.options.brca2',
  PALB2_GENE: 'geneticTesting.positiveGenes.gene.options.palb2',
  CHEK2_GENE: 'geneticTesting.positiveGenes.gene.options.chek2',
  ATM_GENE: 'geneticTesting.positiveGenes.gene.options.atm',
  OTHER_GENE: 'geneticTesting.positiveGenes.gene.options.other',
  TREATMENTS: 'treatments',
  TREATMENT_TYPE: 'treatments[treatmentIdx].type',
  TREATMENT_IS_PERFORMED: 'treatments[treatmentIdx].isPerformed',
  TREATMENT_MANTLE_RADIATION: 'treatment.options.mantleRadiation',
  IS_PRESENT: 'isPresent',
}

QuestionnaireStateManager.StateFieldPaths = {
  PEOPLE: 'persons',
  RELATIONSHIPS: 'relationships',
  PROBAND_ID: 'probandId',
  NOTES: 'notes',
  METRICS: 'metrics',
}

QuestionnaireStateManager.PersonProperties = {
  IS_TWIN: 'twin',
  HAS_CHILDREN: 'hasChildren',
  SHARED_PARENT: 'sharedParent',
  HAS_RELATIVE_SEEN_BY_TEAM: 'hasRelativeSeenByTeam',
  HAS_BEEN_SEEN_BY_TEAM: 'hasBeenSeenByTeam',
  HAS_RELATIVE_SEEN_BY_TEAM_LOCATION: 'hasRelativeSeenByTeamLocation',
  HAS_RELATIVE_SEEN_ELSEWHERE: 'hasRelativeSeenElsewhere',
  HAS_RELATIVE_SEEN_ELSEWHERE_DESC: 'hasRelativeSeenElsewhereDesc',
  USER_DEFINED_RELATIONSHIP: 'userDefinedRelationship',
  HAS_CANCER: 'hasCancer',
  HAS_RELATIVE_HAS_CANCER: 'hasRelativeHasCancer',
  HAS_RELATIVE_HAVE_HYSTERECTOMY: 'hasRelativeHaveHysterectomy',
  HAS_RELATIVE_REMOVED_OVARIES: 'hasRelativeRemovedOvaries',
  HAS_DIFFERENT_GENDER_IDENTITY: 'hasDifferentGenderIdentity',
}

QuestionnaireStateManager.PersonSurgeries = {
  HYSTERECTOMY: 'hysterectomy',
  OOPHORECTOMY: 'oophorectomy',
  LUMPECTOMY: 'lumpectomy',
  MASTECTOMY: 'mastectomy',
  TUBAL_LIGATION: 'tubalLigation',
  SALPINGECTOMY: 'salpingectomy',
}

QuestionnaireStateManager.PersonLists = {
  CONDITIONS: 'conditions',
  PROPERTIES: 'properties',
  SURGERIES: 'surgeries',
  GENETIC_TESTING: 'geneticTesting',
}

QuestionnaireStateManager.PersonConditions = {
  PANCREATITIS: 'HP:0006280',
  PITUITARY: 'pituitary',
  TUMOUR: 'tumour',
  MOLE_NEVI: 'moleNevi',
  POLYPS_REMOVED: 'polypsRemoved',
  ENDOMETRIOSIS: 'HP:0030127',
  BLOOD_CLOT: 'bloodClot',
  LCIS: 'HP:0030076',
}

QuestionnaireStateManager.PersonDiagnosticTests = {
  COLONOSCOPY: 'colonoscopy',
  FIT: 'fit',
  BREAST_MAMMOGRAM: 'breastMammogram',
  PROSTATE_BIOPSY: 'prostateBiopsy',
  ELEVATED_PSA: 'elevatedPSA',
}

QuestionnaireStateManager.NavigationPaths = {
  BACK: 'button.back',
  NEXT: 'button.next',
}

// QuestionnaireStateManager.steps = ['Introduction']

QuestionnaireStateManager.EventType = {
  SAVE_AND_CONTINUE: 'saveAndContinue',
  PREVIOUS_STEP: 'previousStep',
  PAGE_JUMP: 'pageJump',
  GO_BACK: 'goBack',
  SUBMIT: 'submit',
  FEEDBACK: 'feedbackLink',
}

/**
 * These constructor functions return a newly constructed object
 * for those parts of the Person object that are stored as
 * objects inside an array. The keys of the object below correspond
 * to the path of the array within the Person object.
 */
QuestionnaireStateManager.PersonConstructors = {
  conditions: () => {
    return {
      id: uuidv4(),
    }
  },
  properties: () => {
    return {}
  },
  treatments: () => {
    return {}
  },
}

/**
 * Returns a new Person object, with ID initialized as a UUID.
 */
QuestionnaireStateManager.getNewPerson = (newId) => {
  return {
    [PersonFieldPaths.ID]: newId || uuidv4(),
  }
}

/**
 * Returns the last path component (i.e. the field name) from the given path.
 */
QuestionnaireStateManager.getFieldName = (path) => {
  const pathArr = _.toPath(path)

  return pathArr[pathArr.length - 1]
}

/**
 * Return an empty state object to use for initializing the state object.
 * Creates a "people" object and adds an empty
 * proband to it.
 */
QuestionnaireStateManager.getEmptyState = () => {
  const proband = QuestionnaireStateManager.getNewPerson()
  const state = {}

  state[StateFieldPaths.PROBAND_ID] = proband.id
  state[StateFieldPaths.PEOPLE] = {}
  state[StateFieldPaths.PEOPLE][proband.id] = proband
  state[StateFieldPaths.PEOPLE][proband.id].relationshipToProband = 'proband'
  state[StateFieldPaths.RELATIONSHIPS] = []
  // state[StateFieldPaths.METRICS] = { sourcePage: QuestionnaireStateManager.steps[0] }

  return state
}

/**
 * Returns a Person object by the person's ID.
 */
QuestionnaireStateManager.getPersonById = (id, state) => {
  return state[StateFieldPaths.PEOPLE][id]
}

/**
 * Returns the proband's Person object.
 */
QuestionnaireStateManager.getProband = (state) => {
  return QuestionnaireStateManager.getPersonById(state.probandId, state)
}

QuestionnaireStateManager.isPersonEmpty = (person) => {
  const clone = _.clone(person)

  _.unset(clone, PersonFieldPaths.ID)
  _.unset(clone, PersonFieldPaths.SEX)
  _.unset(clone, PersonFieldPaths.RELATIONSHIP_TO_PROBAND)

  if (_.keys(clone).length === 0) {
    return true
  } else if (_.keys(clone).filter((key) => !_.isEmpty(clone[key])).length === 0) {
    return true
  }

  return false
}

QuestionnaireStateManager.questionnaireTypes = {
  SIMPLE: 'simple',
  CANCER: 'cancer',
  ICVD10: 'ICVD1.0',
}

const PersonFieldPaths = QuestionnaireStateManager.PersonFieldPaths
const StateFieldPaths = QuestionnaireStateManager.StateFieldPaths
const PersonProperties = QuestionnaireStateManager.PersonProperties
const PersonConditions = QuestionnaireStateManager.PersonConditions
const PersonSurgeries = QuestionnaireStateManager.PersonSurgeries
const PersonLists = QuestionnaireStateManager.PersonLists
const PersonDiagnosticTests = QuestionnaireStateManager.PersonDiagnosticTests
const getFieldName = QuestionnaireStateManager.getFieldName
const questionnaireTypes = QuestionnaireStateManager.questionnaireTypes
const NavigationPaths = QuestionnaireStateManager.NavigationPaths
const EventType = QuestionnaireStateManager.EventType

export {
  QuestionnaireStateManager,
  PersonFieldPaths,
  getFieldName,
  StateFieldPaths,
  PersonProperties,
  PersonConditions,
  PersonSurgeries,
  PersonLists,
  questionnaireTypes,
  PersonDiagnosticTests,
  NavigationPaths,
  EventType,
}
